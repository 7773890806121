import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import jwtDecode from "jwt-decode";
import { killAuth } from "../oidc/Authentication"
import axios from 'axios';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState({
    state: false,
    loading: true
  });

  const [user, setUser] = useState(false);

  useEffect(() => {

    const sessionToken = Cookies.get("sessionToken");
    const csrfToken = Cookies.get("csrfToken");

    if (sessionToken && csrfToken) {
      try {
        let userData = jwtDecode(sessionToken)
        userData.id_token = sessionToken
        userData.csrf_token = csrfToken
        setUser(userData)
        if (new Date(userData.exp) * 1000 < Date.now()) {
          setIsLoggedIn({
            state: false,
            loading: false
          });
        } else {
          setIsLoggedIn({
            state: true,
            loading: false
          });
        }
      } catch (err) {
        setIsLoggedIn({
          state: false,
          loading: false
        });
      }
    } else if (csrfToken && !sessionToken) {

      try {
        const fetchNewToken = async (csrfToken) => {

          let newTokenConfig = {
            method: 'GET',
            url: process.env.REACT_APP_FUNCTION_URL + "token?" + process.env.REACT_APP_FUNCTION_CODE,
            headers: {
              'Accept': 'application/json',
              'X-CSRF-Token': csrfToken
            }
          };

          const newToken = await (await axios.request(newTokenConfig)).data

          let userData = jwtDecode(newToken.id_token)
          userData.id_token = newToken.id_token
          userData.csrf_token = csrfToken

          setUser(userData)

          const sessionExpirationInDays = new Date(userData.exp * 1000)
          Cookies.set("sessionToken", newToken.id_token, { expires: sessionExpirationInDays });

          setIsLoggedIn({
            state: true,
            loading: false
          })

        }
        fetchNewToken(csrfToken)


      } catch (err) {
        setIsLoggedIn({
          state: false,
          loading: false
        });
      }
    } else {
      setIsLoggedIn({
        state: false,
        loading: false
      })
    }
  }, []);

  const login = (token) => {
    if (token) {

      if (!(token == "No Permissions")) {
        setIsLoggedIn({
          state: true,
          loading: false
        });
        let userData = jwtDecode(token.id_token)
        console.log(userData)
        const sessionExpirationInDays = new Date(userData.exp * 1000)
        const csrfExpirationInDays = token.expires_in / 86000

        Cookies.set("sessionToken", token.id_token, { expires: sessionExpirationInDays });
        Cookies.set("csrfToken", token.csrf_token, { expires: csrfExpirationInDays });
        userData.id_token = token.id_token
        userData.csrf_token = token.csrf_token
        setUser(userData)
      } else {
        setIsLoggedIn({
          state: false,
          loading: false,
          message: "No Permissions"
        });
      }
    } else {
      setIsLoggedIn({
        state: false,
        loading: false,
        message: "Unknown Error"
      });
    }
  };

  const logout = () => {
    // Perform your logout logic here
    const csrfToken = Cookies.get("csrfToken");
    const sessionToken = Cookies.get("sessionToken");
    if (csrfToken && sessionToken) {
      killAuth(sessionToken, csrfToken)
    }

    setIsLoggedIn({
      state: false,
      loading: false
    });
    Cookies.remove("sessionToken")
    Cookies.remove("csrfToken")

  };

  const relogin = () => {
    try {
      const fetchNewToken = async (csrfToken) => {

        let newTokenConfig = {
          method: 'GET',
          url: process.env.REACT_APP_FUNCTION_URL + "token?" + process.env.REACT_APP_FUNCTION_CODE,
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': csrfToken
          }
        };

        const newToken = await (await axios.request(newTokenConfig)).data

        let userData = jwtDecode(newToken.id_token)

        const sessionExpirationInDays = new Date(userData.exp * 1000)
        console.log(newToken)
        console.log(userData)
        userData.id_token = newToken.id_token
        userData.csrf_token = newToken.csrf_token
        
        setUser(userData)
        Cookies.set("sessionToken", newToken.id_token, { expires: sessionExpirationInDays });


        setIsLoggedIn({
          state: true,
          loading: false
        })

      }
      const csrfToken = Cookies.get("csrfToken");
      const sessionToken = Cookies.get("sessionToken");
      console.log(csrfToken)
      console.log(sessionToken)
      console.log(csrfToken && sessionToken == undefined)

      if (csrfToken !== undefined && sessionToken === undefined) {
        fetchNewToken(csrfToken)
        console.log("drinnen")
      } else if (csrfToken !== undefined && sessionToken !== undefined) {

      } else {
        console.log("relogin false")
        setIsLoggedIn({
          state: false,
          loading: false
        })
      }

    } catch (err) {
      console.log("relogin catched")
      setIsLoggedIn({
        state: false,
        loading: false
      })
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, relogin, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
