import React, { useEffect, useState } from 'react'
import { useAuth } from "../oidc/AuthContext";
import { Navigate, useLocation } from 'react-router-dom';
import { getMPCompanies, getMPSubscriptions, getMPCompanyUser, postMPCompanyExternalId, postMPUserExternalId, getCompanyAssociations, getMPProducts, getAdobeCustomers, getAdobeCustomer } from "./MigrationData";
import { BiSearchAlt } from "react-icons/bi";

const Migration = () => {

  function getEditionName(offerId) {
    const adobeEditions = [
      {
        "offerId": "65305410CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit InDesign for teams (Multi EU Languages)"
      },
      {
        "offerId": "65304841CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Creative Cloud for teams All Apps with Adobe Stock (Multi EU Languages)"
      },
      {
        "offerId": "65305118CA03A12",
        "editionName": "VIP 3 Select Adobe XD für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322717CA01A12",
        "editionName": "VIPe 1 Adobe Presenter Licensed for Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305302CA01A12",
        "editionName": "VIP 1 Adobe RoboHelp Office für Teams (Multi European Languages)"
      },
      {
        "offerId": "65323478CA02012",
        "editionName": "VIPe 2 Adobe Express für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324883CA03A12",
        "editionName": "VIP 3 Adobe Dreamweaver Pro für Teams (EU English)"
      },
      {
        "offerId": "65322696CA04A12",
        "editionName": "VIPe 4 Adobe RoboHelp Office für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65305414CA02A12",
        "editionName": "VIP 2 Select Adobe InDesign für Teams (EU English)"
      },
      {
        "offerId": "65324911CA01A12",
        "editionName": "VIP 1 Adobe Illustrator Pro für Teams (EU English)"
      },
      {
        "offerId": "65305285CA02A12",
        "editionName": "VIP 2 Select Adobe Stock für Teams Medium 40 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65324933CA04012",
        "editionName": "Promo VIP 4 Adobe After Effects Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305183CA03A12",
        "editionName": "VIP 3 Select Adobe Illustrator für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304754CA04A12",
        "editionName": "VIP 4 Select Adobe Captivate für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305248CA13A12",
        "editionName": "VIP 13 3 Year Commit Adobe Stock for teams (Small) (Multi EU Languages)"
      },
      {
        "offerId": "65305253CA02A12",
        "editionName": "VIP 2 Select Adobe Stock für Teams Small 10 Standard Assets (EU English)"
      },
      {
        "offerId": "65324839CA01A12",
        "editionName": "VIPe 1 Adobe Dreamweaver Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305521CA01A12",
        "editionName": "VIP 1 Adobe Animate / Flash Professional für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322437CA02A12",
        "editionName": "VIPe 2 Adobe RoboHelp Server für Enterprise (EU English)"
      },
      {
        "offerId": "65304607CA03A12",
        "editionName": "VIP 3 Select Adobe After Effects für Teams (EU English)"
      },
      {
        "offerId": "65317574CA04A12",
        "editionName": "VIP 4 Select Adobe Substance 3D Collection for Teams (Multi European Languages)"
      },
      {
        "offerId": "65324933CA02A12",
        "editionName": "VIP 2 Adobe After Effects Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324823CA03A12",
        "editionName": "VIPe 3 Adobe After Effects Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324884CA03012",
        "editionName": "Promo VIP 3 Dreamweaver Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324863CA12012",
        "editionName": "VIP 12 3 Year Commit Photoshop Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324779CA03012",
        "editionName": "Promo VIP 3 Adobe XD Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324902CA02A12",
        "editionName": "VIP 2 Adobe InCopy Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304841CA01A12",
        "editionName": "VIP 1 Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65304717CA01A12",
        "editionName": "VIP 1 Adobe InCopy für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322656CA02A12",
        "editionName": "VIPe 2 Adobe InDesign for Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324949CA03A12",
        "editionName": "VIPe 3 Adobe Premiere Pro - Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65323478CA01012",
        "editionName": "VIPe 1 Adobe Express für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324897CA03A12",
        "editionName": "VIP 3 Adobe Audition Pro für Teams (EU English)"
      },
      {
        "offerId": "65322708CA01A12",
        "editionName": "VIPe 1 Adobe Captivate für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324884CA04012",
        "editionName": "Promo VIP 4 Dreamweaver Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324802CA04012",
        "editionName": "Promo VIP 4 Select Animate / Flash Professional Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324797CA03012",
        "editionName": "Promo VIP 3 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
      },
      {
        "offerId": "65324938CA03A12",
        "editionName": "VIP 3 Adobe InDesign Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324942CA04A12",
        "editionName": "VIP 4 Adobe InDesign Pro für Teams (EU English)"
      },
      {
        "offerId": "65324938CA01012",
        "editionName": "Promo VIP 1 InDesign Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65325064CA01A12",
        "editionName": "VIPe 1 Adobe Creative Cloud Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322444CA03A12",
        "editionName": "VIPe 3 Adobe Creative Cloud für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322649CA03A12",
        "editionName": "VIPe 3 Acrobat Pro DC für Enterprise (EU English)"
      },
      {
        "offerId": "65304522CA02A12",
        "editionName": "VIP 2 Adobe Acrobat Pro für Teams (EU English)"
      },
      {
        "offerId": "65304607CA01A12",
        "editionName": "VIP 1 Adobe After Effects für Teams (EU English)"
      },
      {
        "offerId": "65322484CAT1A12",
        "editionName": "Tier 1 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65322698CA04A12",
        "editionName": "VIPe 4 Adobe RoboHelp Office für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322607CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Acrobat Standard DC for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324802CA04A12",
        "editionName": "VIP 4 Select Adobe Animate / Flash Professional Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322427CA04A12",
        "editionName": "VIPe 4 Adobe Audition für Enterprise (EU English)"
      },
      {
        "offerId": "65324933CA03012",
        "editionName": "Promo VIP 3 Adobe After Effects Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324826CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Illustrator Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324910CA02A12",
        "editionName": "VIP 2 Adobe Illustrator Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305414CA03A12",
        "editionName": "VIP 3 Select Adobe InDesign für Teams (EU English)"
      },
      {
        "offerId": "65324833CA02A12",
        "editionName": "VIPe 2 Adobe XD Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324841CA02A12",
        "editionName": "VIPe 2 Adobe Dreamweaver Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65304599CA04A12",
        "editionName": "VIP 4 Select Adobe After Effects für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324859CA01A12",
        "editionName": "VIPe 1 Adobe Photoshop Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65304840CA03A12",
        "editionName": "VIP 3 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (EU English)"
      },
      {
        "offerId": "65304754CA02A12",
        "editionName": "VIP 2 Select Adobe Captivate für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324910CA01012",
        "editionName": "Promo VIP 1 Adobe Illustrator Pro for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65304599CA01A12",
        "editionName": "VIP 1 Adobe After Effects für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324823CA01A12",
        "editionName": "VIPe 1 Adobe After Effects Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65304918CA04A12",
        "editionName": "VIP 4 Select Adobe Dreamweaver für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324915CA03A12",
        "editionName": "VIPe 3 Adobe Animate/Flash Professional Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322440CA01A12",
        "editionName": "VIPe 1 Adobe RoboHelp Server für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324782CA04A12",
        "editionName": "VIP 4 Select Adobe XD Pro für Teams (EU English)"
      },
      {
        "offerId": "65304886CA02A12",
        "editionName": "VIP 2 Select Adobe Acrobat DC Standard für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304840CA01A12",
        "editionName": "VIP 1 Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (EU English)"
      },
      {
        "offerId": "65322619CA04A12",
        "editionName": "VIPe 4 Adobe After Effects für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322717CA04A12",
        "editionName": "VIPe 4 Adobe Presenter Licensed for Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324945CA01A12",
        "editionName": "VIPe 1 Adobe Premiere Pro - Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322607CA02A12",
        "editionName": "VIPe 2 Adobe Acrobat DC Standard für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322595CA03A12",
        "editionName": "VIPe 3 Adobe XD für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322582CA03A12",
        "editionName": "VIPe 3 Adobe Animate/Flash Professional für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65317574CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Substance 3D Collection for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65324903CA02A12",
        "editionName": "VIP 2 Adobe InCopy Pro für Teams (EU English)"
      },
      {
        "offerId": "65322653CA02A12",
        "editionName": "VIPe 2 Adobe InDesign for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324833CA03A12",
        "editionName": "VIPe 3 Adobe XD Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65323043CA01A12",
        "editionName": "VIPe 1 Adobe InCopy für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65304522CA03A12",
        "editionName": "VIP 3 Adobe Acrobat Pro für Teams (EU English)"
      },
      {
        "offerId": "65324771CA04012",
        "editionName": "Promo VIP 4 Lightroom Classic Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324897CA02012",
        "editionName": "Promo VIP 2 Audition Pro for Teams (EU English)"
      },
      {
        "offerId": "65305449CA01A12",
        "editionName": "VIP 1 Adobe Dimension für Teams (EU English)"
      },
      {
        "offerId": "65304741CA01A12",
        "editionName": "VIP 1 Adobe Captivate für Teams (EU English)"
      },
      {
        "offerId": "65324883CA04A12",
        "editionName": "VIP 4 Adobe Dreamweaver Pro für Teams (EU English)"
      },
      {
        "offerId": "65324933CA03A12",
        "editionName": "VIP 3 Adobe After Effects Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305063CA02A12",
        "editionName": "VIP 2 Select Adobe Fresco für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324790CA03A12",
        "editionName": "VIPe 3 Adobe Dimension Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322484CAT3A12",
        "editionName": "Tier 3 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65305253CA01A12",
        "editionName": "VIP 1 Adobe Stock für Teams Small 10 Standard Assets (EU English)"
      },
      {
        "offerId": "65324942CA02012",
        "editionName": "Promo VIP 2 InDesign Pro for teams (EU English)"
      },
      {
        "offerId": "65324938CA02012",
        "editionName": "Promo VIP 2 InDesign Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324790CA04A12",
        "editionName": "VIPe 4 Adobe Dimension Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65304741CA04A12",
        "editionName": "VIP 4 Select Adobe Captivate für Teams (EU English)"
      },
      {
        "offerId": "65323053CA04A12",
        "editionName": "VIPe 4 Adobe Substance 3D Collection for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65304841CA04A12",
        "editionName": "VIP 4 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65322619CA02A12",
        "editionName": "VIPe 2 Adobe After Effects für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322581CA03A12",
        "editionName": "VIPe 3 Adobe Animate/Flash Professional für Enterprise (EU English)"
      },
      {
        "offerId": "65324884CA04A12",
        "editionName": "VIP 4 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322698CA03A12",
        "editionName": "VIPe 3 Adobe RoboHelp Office für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324952CA04A12",
        "editionName": "VIPe 4 Adobe Dimension Pro für Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324897CA03012",
        "editionName": "Promo VIP 3 Audition Pro for Teams (EU English)"
      },
      {
        "offerId": "65322565CA04A12",
        "editionName": "VIPe 4 Adobe Dimension für Enterprise (EU English)"
      },
      {
        "offerId": "65322644CA03A12",
        "editionName": "VIPe 3 Adobe Photoshop for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322417CA02A12",
        "editionName": "VIPe 2 Adobe Illustrator für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324785CA02A12",
        "editionName": "VIP 2 Adobe Lightroom Classic Pro für Teams (EU English)"
      },
      {
        "offerId": "65324952CA03A12",
        "editionName": "VIPe 3 Adobe Dimension Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324893CA02A12",
        "editionName": "VIPe 2 Adobe Audition Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322628CA01A12",
        "editionName": "VIPe 1 Adobe Lightroom Classic für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322725CA04A12",
        "editionName": "VIPe 4 Adobe Technical Suite für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322440CA02A12",
        "editionName": "VIPe 2 Adobe RoboHelp Server für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324893CA03A12",
        "editionName": "VIPe 3 Adobe Audition Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324857CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Photoshop Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65322472CA03A12",
        "editionName": "VIPe 3 Adobe Premiere Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324875CA04A12",
        "editionName": "VIPe 4 Adobe InCopy Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322699CA01A12",
        "editionName": "VIPe 1 Adobe Framemaker für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305345CA04A12",
        "editionName": "VIP 4 Select Adobe FrameMaker für Teams (EU English)"
      },
      {
        "offerId": "65323054CA03A12",
        "editionName": "VIPe 3 Adobe Substance 3D Collection für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304482CA04A12",
        "editionName": "VIP 4 Select Adobe Audition für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304886CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Acrobat Standard DC for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324928CA01A12",
        "editionName": "VIPe 1 Adobe InDesign Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305596CA03A12",
        "editionName": "VIP 3 Select Adobe Acrobat Sign für SMB (EU English)"
      },
      {
        "offerId": "65324785CA03012",
        "editionName": "Promo VIP 3 Lightroom Classic Pro for teams (EU English)"
      },
      {
        "offerId": "65324901CA02A12",
        "editionName": "VIP 2 Adobe Audition Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304919CA04A12",
        "editionName": "VIP 4 Select Adobe Dreamweaver für Teams (EU English)"
      },
      {
        "offerId": "65304764CA01A12",
        "editionName": "VIP 1 Adobe Technical Suite für Teams (EU English)"
      },
      {
        "offerId": "65324910CA01A12",
        "editionName": "VIP 1 Adobe Illustrator Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324785CA04A12",
        "editionName": "VIP 4 Adobe Lightroom Classic Pro für Teams (EU English)"
      },
      {
        "offerId": "65324889CA02A12",
        "editionName": "VIPe 2 Adobe Audition Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65305065CA01A12",
        "editionName": "VIP 1 Adobe Fresco für Teams (EU English)"
      },
      {
        "offerId": "65325070CA01A12",
        "editionName": "VIPe 1 Adobe Creative Cloud Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65325084CA01012",
        "editionName": "Promo VIP 1 Adobe Creative Cloud Pro für Teams (EU English)"
      },
      {
        "offerId": "65305345CA02A12",
        "editionName": "VIP 2 Select Adobe FrameMaker für Teams (EU English)"
      },
      {
        "offerId": "65305285CA12A12",
        "editionName": "VIP 12 3 Year Commit Adobe Stock for teams (Medium) (Multi EU Languages)"
      },
      {
        "offerId": "65324828CA03A12",
        "editionName": "VIPe 3 Adobe Illustrator Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322488CA02A12",
        "editionName": "VIPe 2 Adobe FrameMaker Publishing Server für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324849CA03A12",
        "editionName": "VIP 3 Adobe Dimension Pro für Teams (EU English)"
      },
      {
        "offerId": "65305253CA03A12",
        "editionName": "VIP 3 Select Adobe Stock für Teams Small 10 Standard Assets (EU English)"
      },
      {
        "offerId": "65304579CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Creative Cloud for teams All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65324916CA04A12",
        "editionName": "VIPe 4 Adobe Animate/Flash Professional Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324839CA02A12",
        "editionName": "VIPe 2 Adobe Dreamweaver Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304716CA02A12",
        "editionName": "VIP 2 Select Adobe InCopy für Teams (EU English)"
      },
      {
        "offerId": "65324942CA02A12",
        "editionName": "VIP 2 Adobe InDesign Pro für Teams (EU English)"
      },
      {
        "offerId": "65324883CA02012",
        "editionName": "Promo VIP 2 Dreamweaver Pro für Teams (EU English)"
      },
      {
        "offerId": "65324883CA01012",
        "editionName": "Promo VIP 1 Dreamweaver Pro für Teams (EU English)"
      },
      {
        "offerId": "65305370CA01A12",
        "editionName": "VIP 1 Adobe Premiere Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324883CA04012",
        "editionName": "Promo VIP 4 Dreamweaver Pro für Teams (EU English)"
      },
      {
        "offerId": "65322471CA02A12",
        "editionName": "VIPe 2 Adobe Premiere Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65323045CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit InCopy for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65325070CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Creative Cloud Pro for Enterprise All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65324878CA01A12",
        "editionName": "VIPe 1 Adobe InCopy Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324779CA02A12",
        "editionName": "VIP 2 Select Adobe XD Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324779CA01A12",
        "editionName": "VIP 1 Adobe XD Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324828CA04A12",
        "editionName": "VIPe 4 Adobe Illustrator Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322628CA02A12",
        "editionName": "VIPe 2 Adobe Lightroom Classic für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324889CA01A12",
        "editionName": "VIPe 1 Adobe Audition Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65305065CA04A12",
        "editionName": "VIP 4 Select Adobe Fresco für Teams (EU English)"
      },
      {
        "offerId": "65324863CA01A12",
        "editionName": "VIP 1 Adobe Photoshop Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305248CA01A12",
        "editionName": "VIP 1 Adobe Stock für Teams Small 10 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65324779CA02012",
        "editionName": "Promo VIP 2 Adobe XD Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322505CA04A12",
        "editionName": "VIPe 4 Adobe Dreamweaver für Enterprise (EU English)"
      },
      {
        "offerId": "65304919CA01A12",
        "editionName": "VIP 1 Adobe Dreamweaver für Teams (EU English)"
      },
      {
        "offerId": "65305345CA03A12",
        "editionName": "VIP 3 Select Adobe FrameMaker für Teams (EU English)"
      },
      {
        "offerId": "65322603CA01A12",
        "editionName": "VIPe 1 Acrobat DC Standard  für Enterprise (EU English)"
      },
      {
        "offerId": "65324938CA03012",
        "editionName": "Promo VIP 3 InDesign Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322717CA02A12",
        "editionName": "VIPe 2 Adobe Presenter Licensed for Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324802CA03A12",
        "editionName": "VIP 3 Select Adobe Animate / Flash Professional Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65325084CA04012",
        "editionName": "Promo VIP 4 Adobe Creative Cloud Pro für Teams (EU English)"
      },
      {
        "offerId": "65322700CA04A12",
        "editionName": "VIPe 4 Adobe Framemaker für Enterprise (EU English)"
      },
      {
        "offerId": "65324774CA03A12",
        "editionName": "VIPe 3 Adobe Lightroom Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324922CA03A12",
        "editionName": "VIPe 3 Adobe InDesign Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322553CA04A12",
        "editionName": "VIP 4 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65324793CA01012",
        "editionName": "Promo VIP 1 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324771CA03A12",
        "editionName": "VIP 3 Adobe Lightroom Classic Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305369CA01A12",
        "editionName": "VIP 1 Adobe Premiere Pro für Teams (EU English)"
      },
      {
        "offerId": "65322586CA03A12",
        "editionName": "VIP 3 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65322493CA02A12",
        "editionName": "VIPe 2 Adobe FrameMaker Publishing Server für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322484CAT4A12",
        "editionName": "Tier 4 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65324911CA02012",
        "editionName": "Promo VIP 2 Adobe Illustrator Pro for Teams (EU English)"
      },
      {
        "offerId": "65324911CA01012",
        "editionName": "Promo VIP 1 Adobe Illustrator Pro for Teams (EU English)"
      },
      {
        "offerId": "65324933CA02012",
        "editionName": "Promo VIP 2 Adobe After Effects Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65323045CA02A12",
        "editionName": "VIPe 2 Adobe InCopy für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305253CA04A12",
        "editionName": "VIP 4 Select Adobe Stock für Teams Small 10 Standard Assets (EU English)"
      },
      {
        "offerId": "65322565CA03A12",
        "editionName": "VIPe 3 Adobe Dimension für Enterprise (EU English)"
      },
      {
        "offerId": "65322700CA01A12",
        "editionName": "VIPe 1 Adobe Framemaker für Enterprise (EU English)"
      },
      {
        "offerId": "65324799CA01012",
        "editionName": "Promo VIP 1 Animate / Flash Professional Pro for teams (EU English)"
      },
      {
        "offerId": "65305243CA13A12",
        "editionName": "VIP 13 3 Year Commit Adobe Stock for teams (Large) (Multi EU Languages)"
      },
      {
        "offerId": "65322713CA04A12",
        "editionName": "VIPe 4 Adobe Presenter Licensed for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65305183CA01A12",
        "editionName": "VIP 1 Adobe Illustrator für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322607CA01A12",
        "editionName": "VIPe 1 Adobe Acrobat DC Standard für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324897CA01012",
        "editionName": "Promo VIP 1 Audition Pro for Teams (EU English)"
      },
      {
        "offerId": "65322710CA02A12",
        "editionName": "VIPe 2 Adobe Captivate für Enterprise (EU English)"
      },
      {
        "offerId": "65305521CA03A12",
        "editionName": "VIP 3 Select Adobe Animate / Flash Professional für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322488CA04A12",
        "editionName": "VIPe 4 Adobe FrameMaker Publishing Server für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305243CA01A12",
        "editionName": "VIP 1 Adobe Stock für Teams Large 750 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65324802CA01A12",
        "editionName": "VIP 1 Adobe Animate / Flash Professional Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324938CA12012",
        "editionName": "VIP 12 3Year Commit InDesign Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65315888CA03A12",
        "editionName": "VIP 3 Select Adobe RoboHelp Office für Teams (EU English)"
      },
      {
        "offerId": "65322517CA01A12",
        "editionName": "VIPe 1 Adobe Fresco für Enterprise (EU English)"
      },
      {
        "offerId": "65322565CA01A12",
        "editionName": "VIPe 1 Adobe Dimension für Enterprise (EU English)"
      },
      {
        "offerId": "65322437CA03A12",
        "editionName": "VIPe 3 Adobe RoboHelp Server für Enterprise (EU English)"
      },
      {
        "offerId": "65305063CA03A12",
        "editionName": "VIP 3 Select Adobe Fresco für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324849CA04012",
        "editionName": "Promo VIP 4 Dimension Pro für Teams (EU English)"
      },
      {
        "offerId": "65305282CA02A12",
        "editionName": "VIP 2 Select Adobe Stock für Teams Medium 40 Standard Assets (EU English)"
      },
      {
        "offerId": "65322644CA02A12",
        "editionName": "VIPe 2 Adobe Photoshop for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65325070CA03A12",
        "editionName": "VIPe 3 Adobe Creative Cloud für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324949CA02A12",
        "editionName": "VIPe 2 Adobe Premiere Pro - Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324903CA04012",
        "editionName": "Promo VIP 4 InCopy Pro for teams (EU English)"
      },
      {
        "offerId": "65324901CA03A12",
        "editionName": "VIP 3 Adobe Audition Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305248CA14A12",
        "editionName": "VIP 14 3 Year Commit Adobe Stock for teams (Small) (Multi EU Languages)"
      },
      {
        "offerId": "65322422CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Illustrator for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305248CA03A12",
        "editionName": "VIP 3 Select Adobe Stock für Teams Small 10 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65305243CA02A12",
        "editionName": "VIP 2 Select Adobe Stock für Teams Large 750 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65324897CA01A12",
        "editionName": "VIP 1 Adobe Audition Pro für Teams (EU English)"
      },
      {
        "offerId": "65305156CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Photoshop for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322444CA04A12",
        "editionName": "VIPe 4 Adobe Creative Cloud für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322717CA03A12",
        "editionName": "VIPe 3 Adobe Presenter Licensed for Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65325064CA04A12",
        "editionName": "VIPe 4 Adobe Creative Cloud Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322443CA01A12",
        "editionName": "VIPe 1 Adobe Creative Cloud für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304522CA04A12",
        "editionName": "VIP 4 Adobe Acrobat Pro für Teams (EU English)"
      },
      {
        "offerId": "65322648CA01A12",
        "editionName": "VIPe 1 Adobe Acrobat DC Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324849CA04A12",
        "editionName": "VIP 4 Adobe Dimension Pro für Teams (EU English)"
      },
      {
        "offerId": "65322558CA04A12",
        "editionName": "VIP 4 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65305308CA02A12",
        "editionName": "VIP 2 Select Adobe Lightroom Classic für Teams (EU English)"
      },
      {
        "offerId": "65322700CA03A12",
        "editionName": "VIPe 3 Adobe Framemaker für Enterprise (EU English)"
      },
      {
        "offerId": "65324942CA01A12",
        "editionName": "VIP 1 Adobe InDesign Pro für Teams (EU English)"
      },
      {
        "offerId": "65305596CA01A12",
        "editionName": "VIP 1 Adobe Acrobat Sign für SMB (EU English)"
      },
      {
        "offerId": "65324863CA01012",
        "editionName": "Promo VIP 1 Photoshop Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324841CA03A12",
        "editionName": "VIPe 3 Adobe Dreamweaver Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65305370CA04A12",
        "editionName": "VIP 4 Select Adobe Premiere Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324935CA01A12",
        "editionName": "VIP 1 Adobe After Effects Pro für Teams (EU English)"
      },
      {
        "offerId": "65324820CA03A12",
        "editionName": "VIPe 3 Adobe After Effects Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305285CA04A12",
        "editionName": "VIP 4 Select Adobe Stock für Team Medium 40 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65322656CA04A12",
        "editionName": "VIPe 4 Adobe InDesign for Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324935CA04A12",
        "editionName": "VIP 4 Adobe After Effects Pro für Teams (EU English)"
      },
      {
        "offerId": "65304764CA02A12",
        "editionName": "VIP 2 Select Adobe Technical Suite für Teams (EU English)"
      },
      {
        "offerId": "65305599CA04A12",
        "editionName": "VIP 4 Select Adobe Acrobat Sign für SMB (Multi European Languages)"
      },
      {
        "offerId": "65305343CA02A12",
        "editionName": "VIP 2 Select Adobe FrameMaker für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324942CA04012",
        "editionName": "Promo VIP 4 InDesign Pro for teams (EU English)"
      },
      {
        "offerId": "65323054CA04A12",
        "editionName": "VIPe 4 Adobe Substance 3D Collection für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304484CA02A12",
        "editionName": "VIP 2 Select Adobe Audition für Teams (EU English)"
      },
      {
        "offerId": "65324779CA03A12",
        "editionName": "VIP 3 Select Adobe XD Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322648CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Acrobat Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65304717CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit InCopy for teams (Multi EU Languages)"
      },
      {
        "offerId": "65305065CA02A12",
        "editionName": "VIP 2 Select Adobe Fresco für Teams (EU English)"
      },
      {
        "offerId": "65324884CA02A12",
        "editionName": "VIP 2 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324916CA01A12",
        "editionName": "VIPe 1 Adobe Animate/Flash Professional Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65305247CA01A12",
        "editionName": "VIP 1 Adobe Stock für Teams Large 750 Standard Assets (EU English)"
      },
      {
        "offerId": "65322696CA02A12",
        "editionName": "VIPe 2 Adobe RoboHelp Office für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324793CA04012",
        "editionName": "Promo VIP 4 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304888CA04A12",
        "editionName": "VIP 4 Select Adobe Acrobat DC für Teams Standard (EU English)"
      },
      {
        "offerId": "65324893CA01A12",
        "editionName": "VIPe 1 Adobe Audition Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324833CA04A12",
        "editionName": "VIPe 4 Adobe XD Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305345CA01A12",
        "editionName": "VIP 1 Adobe FrameMaker für Teams (EU English)"
      },
      {
        "offerId": "65324863CA03A12",
        "editionName": "VIP 3  Adobe Photoshop Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322582CA02A12",
        "editionName": "VIPe 2 Adobe Animate/Flash Professional für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65323053CA02A12",
        "editionName": "VIPe 2 Adobe Substance 3D Collection for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324849CA01A12",
        "editionName": "VIP 1 Adobe Dimension Pro für Teams (EU English)"
      },
      {
        "offerId": "65324797CA02A12",
        "editionName": "VIP 2 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
      },
      {
        "offerId": "65304764CA04A12",
        "editionName": "VIP 4 Select Adobe Technical Suite für Teams (EU English)"
      },
      {
        "offerId": "65305414CA04A12",
        "editionName": "VIP 4 Select Adobe InDesign für Teams (EU English)"
      },
      {
        "offerId": "65304717CA04A12",
        "editionName": "VIP 4 Select Adobe InCopy für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304575CA02A12",
        "editionName": "VIP 2 Select Adobe Creative Cloud für Teams All Apps (EU English)"
      },
      {
        "offerId": "65317574CA01A12",
        "editionName": "VIP 1 Adobe Substance 3D Collection for Teams (Multi European Languages)"
      },
      {
        "offerId": "65324867CA02012",
        "editionName": "Promo VIP 2 Photoshop Pro for teams (EU English)"
      },
      {
        "offerId": "65322429CA04A12",
        "editionName": "VIPe 4 Adobe Audition für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324828CA02A12",
        "editionName": "VIPe 2 Adobe Illustrator Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322599CA01A12",
        "editionName": "Level 1: Annual plan (EU English) (EURO)"
      },
      {
        "offerId": "65322553CA01A12",
        "editionName": "VIP 1 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65322638CA01A12",
        "editionName": "VIPe 1 Adobe Photoshop für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324867CA04012",
        "editionName": "Promo VIP 4 Photoshop Pro for teams (EU English)"
      },
      {
        "offerId": "65305243CA03A12",
        "editionName": "VIP 3 Select Adobe Stock für Teams Large 750 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65322603CA03A12",
        "editionName": "VIPe 3 Acrobat Standard DC für Enterprise (EU English)"
      },
      {
        "offerId": "65322619CA01A12",
        "editionName": "VIPe 1 Adobe After Effects für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304841CA02A12",
        "editionName": "VIP 2 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65324928CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit InDesign Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65304575CA04A12",
        "editionName": "VIP 4 Select Adobe Creative Cloud für Teams All Apps (EU English)"
      },
      {
        "offerId": "65322505CA02A12",
        "editionName": "VIPe 2 Adobe Dreamweaver für Enterprise (EU English)"
      },
      {
        "offerId": "65304764CA03A12",
        "editionName": "VIP 3 Select Adobe Technical Suite für Teams (EU English)"
      },
      {
        "offerId": "65324785CA01012",
        "editionName": "Promo VIP 1 Lightroom Classic Pro for teams (EU English)"
      },
      {
        "offerId": "65305184CA02A12",
        "editionName": "VIP 2 Select Adobe Illustrator für Teams (EU English)"
      },
      {
        "offerId": "65323053CA03A12",
        "editionName": "VIPe 3 Adobe Substance 3D Collection for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65305369CA02A12",
        "editionName": "VIP 2 Select Adobe Premiere Pro für Teams (EU English)"
      },
      {
        "offerId": "65304579CA02A12",
        "editionName": "VIP 2 Select Adobe Creative Cloud für Teams All Apps (Multi European Languages)"
      },
      {
        "offerId": "65322517CA02A12",
        "editionName": "VIPe 2 Adobe Fresco für Enterprise (EU English)"
      },
      {
        "offerId": "65323045CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit InCopy for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65322599CA03A12",
        "editionName": "Level 3: Annual plan (EU English) (EURO)"
      },
      {
        "offerId": "65322723CA04A12",
        "editionName": "VIPe 4 Adobe Technical Suite für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324928CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit InDesign Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324871CA03012",
        "editionName": "Promo VIP 3 Dimension Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324871CA02A12",
        "editionName": "VIP 2 Adobe Dimension Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324910CA04A12",
        "editionName": "VIP 4 Adobe Illustrator Pro für Teams (Multi European Languages))"
      },
      {
        "offerId": "65322595CA04A12",
        "editionName": "VIPe 4 Adobe XD für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324928CA03A12",
        "editionName": "VIPe 3 Adobe InDesign Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65325070CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Creative Cloud Pro for Enterprise All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65324878CA02A12",
        "editionName": "VIPe 2 Adobe InCopy Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65315887CA02A12",
        "editionName": "VIP 2 Select Adobe RoboHelp Office für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304841CA03A12",
        "editionName": "VIP 3 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65322725CA03A12",
        "editionName": "VIPe 3 Adobe Technical Suite für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324916CA02A12",
        "editionName": "VIPe 2 Adobe Animate/Flash Professional Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324797CA04012",
        "editionName": "Promo VIP 4 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
      },
      {
        "offerId": "65304717CA03A12",
        "editionName": "VIP 3 Select Adobe InCopy für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304717CA02A12",
        "editionName": "VIP 2 Select Adobe InCopy für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305410CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit InDesign for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324828CA01A12",
        "editionName": "VIPe 1 Adobe Illustrator Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324910CA02012",
        "editionName": "Promo VIP 2 Adobe Illustrator Pro for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65324903CA01A12",
        "editionName": "VIP 1 Adobe InCopy Pro für Teams (EU English)"
      },
      {
        "offerId": "65323045CA01A12",
        "editionName": "VIPe 1 Adobe InCopy für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324933CA01012",
        "editionName": "Promo VIP 1 Adobe After Effects Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65323054CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Substance 3D Collection for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65322582CA04A12",
        "editionName": "VIPe 4 Adobe Animate/Flash Professional für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322599CA02A12",
        "editionName": "Level 2: Annual plan (EU English) (EURO)"
      },
      {
        "offerId": "65323045CA04A12",
        "editionName": "VIPe 4 Adobe InCopy für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304886CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Acrobat Standard DC for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322471CA03A12",
        "editionName": "VIPe 3 Adobe Premiere Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324878CA03A12",
        "editionName": "VIPe 3 Adobe InCopy Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65317571CA01A12",
        "editionName": "VIP 1 Adobe Substance 3D Collection for Teams (EU English)"
      },
      {
        "offerId": "65305313CA02A12",
        "editionName": "VIP 2 Select Adobe Lightroom Classic für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324841CA01A12",
        "editionName": "VIPe 1 Adobe Dreamweaver Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322517CA03A12",
        "editionName": "VIPe 3 Adobe Fresco für Enterprise (EU English)"
      },
      {
        "offerId": "65322644CA01A12",
        "editionName": "VIPe 1 Adobe Photoshop für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324774CA01A12",
        "editionName": "VIPe 1 Adobe Lightroom Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65323045CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit InCopy for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324945CA04A12",
        "editionName": "VIPe 4 Adobe Premiere Pro - Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65325064CA02A12",
        "editionName": "VIPe 2 Adobe Creative Cloud Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322534CAT1A12",
        "editionName": "Tier 1 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65322582CA01A12",
        "editionName": "VIPe 1 Adobe Animate/Flash Professional für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324915CA04A12",
        "editionName": "VIPe 4 Adobe Animate/Flash Professional Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305313CA01A12",
        "editionName": "VIP 1 Adobe Lightroom Classic für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324871CA01A12",
        "editionName": "VIP 1 Adobe Dimension Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305521CA04A12",
        "editionName": "VIP 4 Select Adobe Animate / Flash Professional für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324774CA02A12",
        "editionName": "VIPe 2 Adobe Lightroom Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324901CA01012",
        "editionName": "Promo VIP 1 Audition Pro for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65325078CA01012",
        "editionName": "Promo VIP 1 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
      },
      {
        "offerId": "65305121CA01A12",
        "editionName": "VIP 1 Adobe XD für Teams (EU English)"
      },
      {
        "offerId": "65324942CA01012",
        "editionName": "Promo VIP 1 InDesign Pro for teams (EU English)"
      },
      {
        "offerId": "65305414CA01A12",
        "editionName": "VIP 1 Adobe InDesign für Teams (EU English)"
      },
      {
        "offerId": "65304599CA03A12",
        "editionName": "VIP 3 Select Adobe After Effects für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324902CA04A12",
        "editionName": "VIP 4 Adobe InCopy Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305285CA03A12",
        "editionName": "VIP 3 Select Adobe Stock für Team Medium 40 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65315887CA03A12",
        "editionName": "VIP 3 Select Adobe RoboHelp Office für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324910CA03012",
        "editionName": "Promo VIP 3 Adobe Illustrator Pro for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65305183CA04A12",
        "editionName": "VIP 4 Select Adobe Illustrator für Teams (Multi European Languages))"
      },
      {
        "offerId": "65324799CA04012",
        "editionName": "Promo VIP 4 Animate / Flash Professional Pro for teams (EU English)"
      },
      {
        "offerId": "65324863CA02012",
        "editionName": "Promo VIP 2 Photoshop Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322649CA04A12",
        "editionName": "VIPe 4 Acrobat Pro DC für Enterprise (EU English)"
      },
      {
        "offerId": "65324771CA04A12",
        "editionName": "VIP 4 Adobe Lightroom Classic Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304841CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Creative Cloud for teams All Apps with Adobe Stock (Multi EU Languages)"
      },
      {
        "offerId": "65324793CA01A12",
        "editionName": "VIP 1 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322519CA01A12",
        "editionName": "VIPe 1 Adobe Fresco für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65315888CA02A12",
        "editionName": "VIP 2 Select Adobe RoboHelp Office für Teams (EU English)"
      },
      {
        "offerId": "65304886CA01A12",
        "editionName": "VIP 1 Adobe Acrobat DC für Teams Standard (Multi European Languages)"
      },
      {
        "offerId": "65322504CA01A12",
        "editionName": "VIPe 1 Adobe Dreamweaver für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324863CA04A12",
        "editionName": "VIP 4 Select Adobe Photoshop Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322656CA01A12",
        "editionName": "VIPe 1 Adobe InDesign for Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304888CA03A12",
        "editionName": "VIP 3 Select Adobe Acrobat DC für Teams Standard (EU English)"
      },
      {
        "offerId": "65304579CA04A12",
        "editionName": "VIP 4 Select Adobe Creative Cloud für Teams All Apps (Multi European Languages)"
      },
      {
        "offerId": "65305599CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Acrobat Sign for SMB (Multi EU Languages)"
      },
      {
        "offerId": "65323054CA01A12",
        "editionName": "VIPe 1 Adobe Substance 3D Collection für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324799CA03012",
        "editionName": "Promo VIP 3 Animate / Flash Professional Pro for teams (EU English)"
      },
      {
        "offerId": "65324779CA01012",
        "editionName": "Promo VIP 1 Adobe XD Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322417CA03A12",
        "editionName": "VIPe 3 Adobe Illustrator für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65304716CA01A12",
        "editionName": "VIP 1 Adobe InCopy für Teams (EU English)"
      },
      {
        "offerId": "65322699CA03A12",
        "editionName": "VIPe 3 Adobe Framemaker für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322630CA02A12",
        "editionName": "VIPe 2 Adobe Lightroom Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324802CA02A12",
        "editionName": "VIP 2 Select Adobe Animate / Flash Professional Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65325084CA04A12",
        "editionName": "VIP 4 Adobe Creative Cloud Pro für Teams (EU English)"
      },
      {
        "offerId": "65324802CA01012",
        "editionName": "Promo VIP 1 Select Animate / Flash Professional Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322619CA03A12",
        "editionName": "VIPe 3 Adobe After Effects für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65325070CA04A12",
        "editionName": "VIPe 4 Adobe Creative Cloud Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322588CA01A12",
        "editionName": "VIP 1 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65305156CA03A12",
        "editionName": "VIP 3 Select Adobe Photoshop für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304754CA01A12",
        "editionName": "VIP 1 Adobe Captivate für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324884CA01012",
        "editionName": "VIP 1 Adobe Dreamweaver Pro für Teams First-Year-Promo (Multi EU Languages)"
      },
      {
        "offerId": "65322588CA03A12",
        "editionName": "VIP 3 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65324871CA04A12",
        "editionName": "VIP 4 Adobe Dimension für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324771CA01A12",
        "editionName": "VIP 1 Adobe Lightroom Classic Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305183CA02A12",
        "editionName": "VIP 2 Select Adobe Illustrator für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305313CA03A12",
        "editionName": "VIP 3 Select Adobe Lightroom Classic für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305065CA03A12",
        "editionName": "VIP 3 Select Adobe Fresco für Teams (EU English)"
      },
      {
        "offerId": "65322586CA01A12",
        "editionName": "VIP 1 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65323053CA01A12",
        "editionName": "VIPe 1 Adobe Substance 3D Collection for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324893CA04A12",
        "editionName": "VIPe 4 Adobe Audition Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65325084CA03A12",
        "editionName": "VIP 3 Adobe Creative Cloud Pro für Teams (EU English)"
      },
      {
        "offerId": "65324945CA02A12",
        "editionName": "VIPe 2 Adobe Premiere Pro - Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324942CA03A12",
        "editionName": "VIP 3 Adobe InDesign Pro für Teams (EU English)"
      },
      {
        "offerId": "65305343CA04A12",
        "editionName": "VIP 4 Select Adobe FrameMaker für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324826CA04A12",
        "editionName": "VIPe 4 Adobe Illustrator Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324902CA02012",
        "editionName": "Promo VIP 2 InCopy Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65304918CA03A12",
        "editionName": "VIP 3 Select Adobe Dreamweaver für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304599CA02A12",
        "editionName": "VIP 2 Select Adobe After Effects für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305450CA02A12",
        "editionName": "VIP 2 Select Adobe Dimension für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324922CA04A12",
        "editionName": "VIPe 4 Adobe InDesign Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322437CA01A12",
        "editionName": "VIPe 1 Adobe RoboHelp Server für Enterprise (EU English)"
      },
      {
        "offerId": "65322710CA01A12",
        "editionName": "VIPe 1 Adobe Captivate für Enterprise (EU English)"
      },
      {
        "offerId": "65304521CA03A12",
        "editionName": "VIP 3 Adobe Acrobat Pro für Teams (Multi European Language)"
      },
      {
        "offerId": "65322623CA01A12",
        "editionName": "VIPe 1 Adobe After Effects für Enterprise (EU English)"
      },
      {
        "offerId": "65324911CA04A12",
        "editionName": "VIP 4 Adobe Illustrator Pro für Teams (EU English)"
      },
      {
        "offerId": "65324782CA02A12",
        "editionName": "VIP 2 Select Adobe XD Pro für Teams (EU English)"
      },
      {
        "offerId": "65322588CA02A12",
        "editionName": "VIP 2 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65322429CA01A12",
        "editionName": "VIPe 1 Adobe Audition für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322699CA04A12",
        "editionName": "VIPe 4 Adobe Framemaker für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65325078CA01A12",
        "editionName": "VIP 1 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
      },
      {
        "offerId": "65305118CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Adobe XD for teams (Multi EU Languages)"
      },
      {
        "offerId": "65304741CA03A12",
        "editionName": "VIP 3 Select Adobe Captivate für Teams (EU English)"
      },
      {
        "offerId": "65324849CA02A12",
        "editionName": "VIP 2 Adobe Dimension Pro für Teams (EU English)"
      },
      {
        "offerId": "65325084CA01A12",
        "editionName": "VIP 1 Adobe Creative Cloud Pro für Teams All Apps (EU English)"
      },
      {
        "offerId": "65324771CA01012",
        "editionName": "Promo VIP 1 Lightroom Classic Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324826CA03A12",
        "editionName": "VIPe 3 Adobe Illustrator Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324799CA02A12",
        "editionName": "VIP 2 Select Adobe Animate / Flash Professional Pro für Teams (EU English)"
      },
      {
        "offerId": "65324782CA04012",
        "editionName": "Promo VIP 4 Adobe XD Pro für Teams (EU English)"
      },
      {
        "offerId": "65322653CA01A12",
        "editionName": "VIPe 1 Adobe InDesign for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322444CA02A12",
        "editionName": "VIPe 2 Adobe Creative Cloud für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65317571CA04A12",
        "editionName": "VIP 4 Select Adobe Substance 3D Collection for Teams (EU English)"
      },
      {
        "offerId": "65324857CA02A12",
        "editionName": "VIPe 2 Adobe Photoshop Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322422CA01A12",
        "editionName": "VIPe 1 Adobe Illustrator für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324859CA03A12",
        "editionName": "VIPe 3 Adobe Photoshop Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322649CA02A12",
        "editionName": "VIPe 2 Acrobat Pro DC für Enterprise (EU English)"
      },
      {
        "offerId": "65305121CA04A12",
        "editionName": "VIP 4 Select Adobe XD für Teams (EU English)"
      },
      {
        "offerId": "65304919CA02A12",
        "editionName": "VIP 2 Select Adobe Dreamweaver für Teams (EU English)"
      },
      {
        "offerId": "65322427CA01A12",
        "editionName": "VIPe 1 Adobe Audition für Enterprise (EU English)"
      },
      {
        "offerId": "65322504CA03A12",
        "editionName": "VIPe 3 Adobe Dreamweaver für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324793CA03A12",
        "editionName": "VIP 3 Select Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322603CA04A12",
        "editionName": "VIPe 4 Acrobat DC Standard für Enterprise (EU English)"
      },
      {
        "offerId": "65322710CA03A12",
        "editionName": "VIPe 3 Adobe Captivate für Enterprise (EU English)"
      },
      {
        "offerId": "65324771CA02A12",
        "editionName": "VIP 2 Select Adobe Lightroom Classic Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305300CA01A12",
        "editionName": "VIP 1 Adobe RoboHelp Office für Teams (EU English)"
      },
      {
        "offerId": "65322427CA02A12",
        "editionName": "VIPe 2 Adobe Audition für Enterprise (EU English)"
      },
      {
        "offerId": "65322648CA03A12",
        "editionName": "VIPe 3 Adobe Acrobat DC Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65323043CA04A12",
        "editionName": "VIPe 4 Adobe InCopy für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65305285CA01A12",
        "editionName": "VIP 1 Adobe Stock für Teams Medium 40 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65324771CA02012",
        "editionName": "Promo VIP 2 Lightroom Classic Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65323054CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Substance 3D Collection for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65322623CA04A12",
        "editionName": "VIPe 4 Adobe After Effects für Enterprise (EU English)"
      },
      {
        "offerId": "65304717CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit InCopy for teams (Multi EU Languages)"
      },
      {
        "offerId": "65323043CA02A12",
        "editionName": "VIPe 2 Adobe InCopy für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324776CA04A12",
        "editionName": "VIPe 4 Adobe Lightroom Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322581CA02A12",
        "editionName": "VIPe 2 Adobe Animate/Flash Professional für Enterprise (EU English)"
      },
      {
        "offerId": "65324863CA02A12",
        "editionName": "VIP 2 Adobe Photoshop Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324901CA04012",
        "editionName": "Promo VIP 4 Audition Pro for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65305158CA04A12",
        "editionName": "VIP 4 Select Adobe Photoshop für Teams (EU English)"
      },
      {
        "offerId": "65305450CA01A12",
        "editionName": "VIP 1 Adobe Dimension für Teams (Multi European Languages)"
      },
      {
        "offerId": "65325078CA02A12",
        "editionName": "VIP 2 Adobe Creative Cloud Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305118CA01A12",
        "editionName": "VIP 1 Adobe XD für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322472CA04A12",
        "editionName": "VIPe 4 Adobe Premiere Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65304919CA03A12",
        "editionName": "VIP 3 Select Adobe Dreamweaver für Teams (EU English)"
      },
      {
        "offerId": "65324859CA04A12",
        "editionName": "VIPe 4 Adobe Photoshop Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324793CA02012",
        "editionName": "Promo VIP 2 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304886CA04A12",
        "editionName": "VIP 4 Select Adobe Acrobat DC für Teams Standard (Multi European Languages)"
      },
      {
        "offerId": "65305599CA01A12",
        "editionName": "VIP 1 Adobe Acrobat Sign für SMB (Multi European Languages)"
      },
      {
        "offerId": "65322417CA04A12",
        "editionName": "VIPe 4 Adobe Illustrator für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65323043CA03A12",
        "editionName": "VIPe 3 Adobe InCopy für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65305521CA02A12",
        "editionName": "VIP 2 Select Adobe Animate / Flash Professional für Teams (Multi European Languages)"
      },
      {
        "offerId": "65323054CA02A12",
        "editionName": "VIPe 2 Adobe Substance 3D Collection für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304521CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Acrobat Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324782CA03012",
        "editionName": "Promo VIP 3 Adobe XD Pro für Teams (EU English)"
      },
      {
        "offerId": "65304888CA02A12",
        "editionName": "VIP 2 Select Adobe Acrobat DC für Teams Standard (EU English)"
      },
      {
        "offerId": "65305118CA02A12",
        "editionName": "VIP 2 Select Adobe XD für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324820CA04A12",
        "editionName": "VIPe 4 Adobe After Effects Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324823CA04A12",
        "editionName": "VIPe 4 Adobe After Effects Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65305308CA03A12",
        "editionName": "VIP 3 Select Adobe Lightroom Classic für Teams (EU English)"
      },
      {
        "offerId": "65322563CA03A12",
        "editionName": "VIPe 3 Adobe Dimension für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324782CA01A12",
        "editionName": "VIP 1 Adobe XD Pro für Teams (EU English)"
      },
      {
        "offerId": "65324863CA04012",
        "editionName": "Promo VIP 4 Photoshop Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324875CA02A12",
        "editionName": "VIPe 2 Adobe InCopy Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324928CA04A12",
        "editionName": "VIPe 4 Adobe InDesign Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304575CA03A12",
        "editionName": "VIP 3 Select Adobe Creative Cloud für Teams All Apps (EU English)"
      },
      {
        "offerId": "65322434CAT3A12",
        "editionName": "Tier 3 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65324857CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Photoshop Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305121CA03A12",
        "editionName": "VIP 3 Select Adobe XD für Teams (EU English)"
      },
      {
        "offerId": "65322708CA02A12",
        "editionName": "VIPe 2 Adobe Captivate für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305156CA04A12",
        "editionName": "VIP 4 Select Adobe Photoshop für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324915CA02A12",
        "editionName": "VIPe 2 Adobe Animate/Flash Professional Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324797CA01A12",
        "editionName": "VIP 1 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
      },
      {
        "offerId": "65304607CA04A12",
        "editionName": "VIP 4 Select Adobe After Effects für Teams (EU English)"
      },
      {
        "offerId": "65325078CA04012",
        "editionName": "Promo VIP 4 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
      },
      {
        "offerId": "65305248CA04A12",
        "editionName": "VIP 4 Select Stock für Teams Small 10 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65324799CA04A12",
        "editionName": "VIP 4 Select Adobe Animate / Flash Professional Pro für Teams (EU English)"
      },
      {
        "offerId": "65322429CA02A12",
        "editionName": "VIPe 2 Adobe Audition für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305158CA01A12",
        "editionName": "VIP 1 Adobe Photoshop für Teams (EU English)"
      },
      {
        "offerId": "65324776CA01A12",
        "editionName": "VIPe 1 Adobe Lightroom Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65323045CA03A12",
        "editionName": "VIPe 3 Adobe InCopy für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324833CA01A12",
        "editionName": "VIPe 1 Adobe XD Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322563CA01A12",
        "editionName": "VIPe 1 Adobe Dimension für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322558CA02A12",
        "editionName": "VIP 2 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65324871CA03A12",
        "editionName": "VIP 3 Adobe Dimension für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324911CA03A12",
        "editionName": "VIP 3 Adobe Illustrator Pro für Teams (EU English)"
      },
      {
        "offerId": "65322471CA01A12",
        "editionName": "VIPe 1 Adobe Premiere Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304575CA01A12",
        "editionName": "VIP 1 Adobe Creative Cloud für Teams All Apps (EU English)"
      },
      {
        "offerId": "65322440CA03A12",
        "editionName": "VIPe 3 Adobe RoboHelp Server für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322440CA04A12",
        "editionName": "VIPe 4 Adobe RoboHelp Server für Enterprise(Multi European Languages)"
      },
      {
        "offerId": "65322656CA03A12",
        "editionName": "VIPe 3 Adobe InDesign for Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324901CA01A12",
        "editionName": "VIP 1 Adobe Audition Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322422CA03A12",
        "editionName": "VIPe 3 Adobe Illustrator für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322698CA01A12",
        "editionName": "VIPe 1 Adobe RoboHelp Office für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322537CAT2A12",
        "editionName": "Tier 2 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65322553CA03A12",
        "editionName": "VIP 3 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65324785CA04012",
        "editionName": "Promo VIP 4 Lightroom Classic Pro for teams (EU English)"
      },
      {
        "offerId": "65305118CA04A12",
        "editionName": "VIP 4 Select Adobe XD für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322586CA02A12",
        "editionName": "VIP 2 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65324938CA02A12",
        "editionName": "VIP 2 Adobe InDesign Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305121CA02A12",
        "editionName": "VIP 2 Select Adobe XD für Teams (EU English)"
      },
      {
        "offerId": "65325078CA03A12",
        "editionName": "VIP 3 Adobe Creative Cloud Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324901CA04A12",
        "editionName": "VIP 4 Adobe Audition Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324849CA01012",
        "editionName": "Promo VIP 1 Dimension Pro für Teams (EU English)"
      },
      {
        "offerId": "65324889CA04A12",
        "editionName": "VIPe 4 Adobe Audition Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322443CA02A12",
        "editionName": "VIPe 2 Adobe Creative Cloud für Enterprise (EU English)"
      },
      {
        "offerId": "65304521CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Acrobat Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324902CA03A12",
        "editionName": "VIP 3 Adobe InCopy Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304918CA01A12",
        "editionName": "VIP 1 Adobe Dreamweaver für Teams (Multi European Languages)"
      },
      {
        "offerId": "65323478CA04012",
        "editionName": "VIPe 4 Adobe Express für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304579CA03A12",
        "editionName": "VIP 3 Select Adobe Creative Cloud für Teams All Apps (Multi European Languages)"
      },
      {
        "offerId": "65322700CA02A12",
        "editionName": "VIPe 2 Adobe Framemaker für Enterprise (EU English)"
      },
      {
        "offerId": "65322630CA03A12",
        "editionName": "VIPe 3 Adobe Lightroom Classic für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322696CA03A12",
        "editionName": "VIPe 3 Adobe RoboHelp Office für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324835CA02A12",
        "editionName": "VIPe 2 Adobe XD Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65305410CA03A12",
        "editionName": "VIP 3 Select Adobe InDesign für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324884CA02012",
        "editionName": "Promo VIP 2 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322534CAT4A12",
        "editionName": "Tier 4 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65322644CA04A12",
        "editionName": "VIPe 4 Adobe Photoshop for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322565CA02A12",
        "editionName": "VIPe 2 Adobe Dimension für Enterprise (EU English)"
      },
      {
        "offerId": "65324935CA04012",
        "editionName": "Promo VIP 4 Adobe After Effects Pro für Teams (EU English"
      },
      {
        "offerId": "65322648CA02A12",
        "editionName": "VIPe 2 Adobe Acrobat DC Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324889CA03A12",
        "editionName": "VIPe 3 Adobe Audition Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322638CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Photoshop for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324790CA01A12",
        "editionName": "VIPe 1 Adobe Dimension Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324785CA01A12",
        "editionName": "VIP 1 Adobe Lightroom Classic Pro für Teams (EU English)"
      },
      {
        "offerId": "65305449CA03A12",
        "editionName": "VIP 3 Select Adobe Dimension für Teams (EU English)"
      },
      {
        "offerId": "65322504CA04A12",
        "editionName": "VIPe 4 Adobe Dreamweaver für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65317574CA02A12",
        "editionName": "VIP 2 Select Adobe Substance 3D Collection for Teams (Multi European Languages)"
      },
      {
        "offerId": "65304754CA03A12",
        "editionName": "VIP 3 Select Adobe Captivate für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304579CA01A12",
        "editionName": "VIP 1 Adobe Creative Cloud für Teams All Apps (Multi European Languages)"
      },
      {
        "offerId": "65324779CA04A12",
        "editionName": "VIP 4 Select Adobe XD Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324903CA01012",
        "editionName": "Promo VIP 1 InCopy Pro for teams (EU English)"
      },
      {
        "offerId": "65324799CA01A12",
        "editionName": "VIP 1 Adobe Animate / Flash Professional Pro für Teams (EU English)"
      },
      {
        "offerId": "65324867CA01A12",
        "editionName": "VIP 1 Adobe Photoshop Pro für Teams (EU English)"
      },
      {
        "offerId": "65325070CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Creative Cloud Pro for Enterprise All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65322653CA04A12",
        "editionName": "VIPe 4 Adobe InDesign for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65304888CA01A12",
        "editionName": "VIP 1 Adobe Acrobat DC für Teams Standard (EU English)"
      },
      {
        "offerId": "65324797CA01012",
        "editionName": "Promo VIP 1 Premiere Pro - Pro für Teams (EU English)"
      },
      {
        "offerId": "65305410CA04A12",
        "editionName": "VIP 4 Select Adobe InDesign für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324903CA04A12",
        "editionName": "VIP 4 Adobe InCopy Pro für Teams (EU English)"
      },
      {
        "offerId": "65304484CA04A12",
        "editionName": "VIP 4 Select Adobe Audition für Teams (EU English)"
      },
      {
        "offerId": "65322607CA03A12",
        "editionName": "VIPe 3 Adobe Acrobat DC Standard für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322638CA04A12",
        "editionName": "VIPe 4 Adobe Photoshop für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305596CA02A12",
        "editionName": "VIP 2 Select Adobe Acrobat Sign für SMB (EU English)"
      },
      {
        "offerId": "65305507CA02A12",
        "editionName": "VIP 2 Select Adobe Animate / Flash Professional für Teams (EU English)"
      },
      {
        "offerId": "65324897CA04012",
        "editionName": "Promo VIP 4 Audition Pro for Teams (EU English)"
      },
      {
        "offerId": "65324797CA04A12",
        "editionName": "VIP 4 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
      },
      {
        "offerId": "65304754CA14A12",
        "editionName": "VIP 14 3 Year Commit Captivate for teams (Multi EU Languages)"
      },
      {
        "offerId": "65305282CA01A12",
        "editionName": "VIP 1 Adobe Stock für Teams Medium 40 Standard Assets (EU English)"
      },
      {
        "offerId": "65324935CA01012",
        "editionName": "Promo VIP 1 Adobe After Effects Pro für Teams (EU English)"
      },
      {
        "offerId": "65305243CA04A12",
        "editionName": "VIP 4 Select Adobe Stock für Teams Large 750 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65322443CA04A12",
        "editionName": "VIPe 4 Adobe Creative Cloud für Enterprise (EU English)"
      },
      {
        "offerId": "65322638CA02A12",
        "editionName": "VIPe 2 Adobe Photoshop für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322696CA01A12",
        "editionName": "VIPe 1 Adobe RoboHelp Office für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65305599CA03A12",
        "editionName": "VIP 3 Select Adobe Acrobat Sign für SMB (Multi European Languages)"
      },
      {
        "offerId": "65322493CA04A12",
        "editionName": "VIPe 4 Adobe FrameMaker Publishing Server für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324875CA01A12",
        "editionName": "VIPe 1 Adobe InCopy Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322725CA01A12",
        "editionName": "VIPe 1 Adobe Technical Suite für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305596CA04A12",
        "editionName": "VIP 4 Select Adobe Acrobat Sign für SMB (EU English)"
      },
      {
        "offerId": "65324790CA02A12",
        "editionName": "VIPe 2 Adobe Dimension Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322599CA04A12",
        "editionName": "Level 4: Annual plan (EU English) (EURO)"
      },
      {
        "offerId": "65324826CA01A12",
        "editionName": "VIPe 1 Adobe Illustrator Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324857CA01A12",
        "editionName": "VIPe 1 Adobe Photoshop Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304716CA04A12",
        "editionName": "VIP 4 Select Adobe InCopy für Teams (EU English)"
      },
      {
        "offerId": "65305285CA13A12",
        "editionName": "VIP 13 3 Year Commit Adobe Stock for teams (Medium) (Multi EU Languages)"
      },
      {
        "offerId": "65324839CA04A12",
        "editionName": "VIPe 4 Adobe Dreamweaver Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304521CA01A12",
        "editionName": "VIP 1 Adobe Acrobat Pro für Teams (Multi European Language)"
      },
      {
        "offerId": "65324938CA01A12",
        "editionName": "VIP 1 Adobe InDesign Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324793CA04A12",
        "editionName": "VIP 4 Select Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324774CA04A12",
        "editionName": "VIPe 4 Adobe Lightroom Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304741CA02A12",
        "editionName": "VIP 2 Select Adobe Captivate für Teams (EU English)"
      },
      {
        "offerId": "65305063CA01A12",
        "editionName": "VIP 1 Adobe Fresco für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322638CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Photoshop for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65325084CA03012",
        "editionName": "Promo VIP 3 Adobe Creative Cloud Pro für Teams (EU English)"
      },
      {
        "offerId": "65322563CA04A12",
        "editionName": "VIPe 4 Adobe Dimension für Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65322537CAT3A12",
        "editionName": "Tier 3 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65304763CA03A12",
        "editionName": "VIP 3 Select Adobe Technical Suite für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322628CA03A12",
        "editionName": "VIPe 3 Adobe Lightroom Classic für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324793CA03012",
        "editionName": "Promo VIP 3 Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324922CA02A12",
        "editionName": "VIPe 2 Adobe InDesign Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65315887CA04A12",
        "editionName": "VIP 4 Select Adobe RoboHelp Office für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324871CA02012",
        "editionName": "Promo VIP 2 Dimension Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65325078CA04A12",
        "editionName": "VIP 4 Adobe Creative Cloud Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304484CA03A12",
        "editionName": "VIP 3 Select Adobe Audition für Teams (EU English)"
      },
      {
        "offerId": "65322581CA04A12",
        "editionName": "VIPe 4 Adobe Animate/Flash Professional für Enterprise (EU English)"
      },
      {
        "offerId": "65324902CA03012",
        "editionName": "Promo VIP 3 InCopy Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324793CA02A12",
        "editionName": "VIP 2 Select Adobe Premiere Pro - Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322488CA01A12",
        "editionName": "VIPe 1 Adobe FrameMaker Publishing Server für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305247CA03A12",
        "editionName": "VIP 3 Select Adobe Stock für Teams Large 750 Standard Assets (EU English)"
      },
      {
        "offerId": "65305183CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Illustrator for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324857CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Photoshop Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324799CA03A12",
        "editionName": "VIP 3 Select Adobe Animate / Flash Professional Pro für Teams (EU English)"
      },
      {
        "offerId": "65305599CA02A12",
        "editionName": "VIP 2 Select Adobe Acrobat Sign für SMB (Multi European Languages)"
      },
      {
        "offerId": "65324949CA01A12",
        "editionName": "VIPe 1 Adobe Premiere Pro - Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324902CA01A12",
        "editionName": "VIP 1 Adobe InCopy Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324922CA01A12",
        "editionName": "VIPe 1 Adobe InDesign Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65325084CA02A12",
        "editionName": "VIP 2 Adobe Creative Cloud Pro für Teams (EU English)"
      },
      {
        "offerId": "65305156CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Photoshop for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324928CA02A12",
        "editionName": "VIPe 2 Adobe InDesign Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305282CA04A12",
        "editionName": "VIP 4 Select Adobe Stock für Teams Medium 40 Standard Assets (EU English)"
      },
      {
        "offerId": "65322713CA01A12",
        "editionName": "VIPe 1 Adobe Presenter Licensed for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322434CAT1A12",
        "editionName": "Tier 1 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65322519CA02A12",
        "editionName": "VIPe 2 Adobe Fresco für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324839CA03A12",
        "editionName": "VIPe 3 Adobe Dreamweaver Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322422CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Illustrator for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305410CA01A12",
        "editionName": "VIP 1 Adobe InDesign für Teams (Multi European Languages)"
      },
      {
        "offerId": "65317571CA02A12",
        "editionName": "VIP 2 Select Adobe Substance 3D Collection for Teams (EU English)"
      },
      {
        "offerId": "65322638CA03A12",
        "editionName": "VIPe 3 Adobe Photoshop für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324782CA02012",
        "editionName": "Promo VIP 2 Adobe XD Pro für Teams (EU English)"
      },
      {
        "offerId": "65324863CA03012",
        "editionName": "Promo VIP 3 Photoshop Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322553CA02A12",
        "editionName": "VIP 2 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65324952CA02A12",
        "editionName": "VIPe 2 Adobe Dimension Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324826CA02A12",
        "editionName": "VIPe 2 Adobe Illustrator Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322505CA03A12",
        "editionName": "VIPe 3 Adobe Dreamweaver für Enterprise (EU English)"
      },
      {
        "offerId": "65324916CA03A12",
        "editionName": "VIPe 3 Adobe Animate/Flash Professional Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65305410CA02A12",
        "editionName": "VIP 2 Select Adobe InDesign für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324910CA03A12",
        "editionName": "VIP 3 Adobe Illustrator Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324835CA01A12",
        "editionName": "VIPe 1 Adobe XD Pro für Enterprise (EU English) (EURO)"
      },
      {
        "offerId": "65324903CA02012",
        "editionName": "Promo VIP 2 InCopy Pro for teams (EU English)"
      },
      {
        "offerId": "65305184CA03A12",
        "editionName": "VIP 3 Select Adobe Illustrator für Teams (EU English)"
      },
      {
        "offerId": "65324835CA03A12",
        "editionName": "VIPe 3 Adobe XD Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324867CA02A12",
        "editionName": "VIP 2 Adobe Photoshop Pro für Teams (EU English)"
      },
      {
        "offerId": "65322586CA04A12",
        "editionName": "VIP 4 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65324938CA04A12",
        "editionName": "VIP 4 Adobe InDesign Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324857CA04A12",
        "editionName": "VIPe 4 Adobe Photoshop für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322699CA02A12",
        "editionName": "VIPe 2 Adobe Framemaker für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322429CA03A12",
        "editionName": "VIPe 3 Adobe Audition für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324915CA01A12",
        "editionName": "VIPe 1 Adobe Animate/Flash Professional Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304521CA02A12",
        "editionName": "VIP 2 Adobe Acrobat Pro für Teams (Multi European Language)"
      },
      {
        "offerId": "65325078CA03012",
        "editionName": "Promo VIP 3 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
      },
      {
        "offerId": "65324867CA03012",
        "editionName": "Promo VIP 3 Photoshop Pro for teams (EU English)"
      },
      {
        "offerId": "65322595CA01A12",
        "editionName": "VIPe 1 Adobe XD für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304482CA01A12",
        "editionName": "VIP 1 Adobe Audition für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324802CA02012",
        "editionName": "Promo VIP 2 Select Animate / Flash Professional Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324928CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit InDesign Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305343CA01A12",
        "editionName": "VIP 1 Adobe FrameMaker für Teams (Multi European Languages)"
      },
      {
        "offerId": "65323054CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Substance 3D Collection for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324875CA03A12",
        "editionName": "VIPe 3 Adobe InCopy Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322558CA03A12",
        "editionName": "VIP 3 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65322519CA03A12",
        "editionName": "VIPe 3 Adobe Fresco für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304717CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit InCopy for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324782CA01012",
        "editionName": "Promo VIP 1 Adobe XD Pro für Teams (EU English)"
      },
      {
        "offerId": "65322493CA03A12",
        "editionName": "VIPe 3 Adobe FrameMaker Publishing Server für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324826CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Illustrator Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65304763CA04A12",
        "editionName": "VIP 4 Select Adobe Technical Suite für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305369CA04A12",
        "editionName": "VIP 4 Select Adobe Premiere Pro für Teams (EU English)"
      },
      {
        "offerId": "65324785CA02012",
        "editionName": "Promo VIP 2 Lightroom Classic Pro for teams (EU English)"
      },
      {
        "offerId": "65324942CA03012",
        "editionName": "Promo VIP 3 InDesign Pro for teams (EU English)"
      },
      {
        "offerId": "65305450CA03A12",
        "editionName": "VIP 3 Select Adobe Dimension für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305158CA02A12",
        "editionName": "VIP 2 Select Adobe Photoshop für Teams (EU English)"
      },
      {
        "offerId": "65325084CA02012",
        "editionName": "Promo VIP 2 Adobe Creative Cloud Pro für Teams (EU English)"
      },
      {
        "offerId": "65324835CA04A12",
        "editionName": "VIPe 4 Adobe XD Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324779CA04012",
        "editionName": "Promo VIP 4 Adobe XD Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304886CA03A12",
        "editionName": "VIP 3 Select Adobe Acrobat DC für Teams Standard (Multi European Languages)"
      },
      {
        "offerId": "65322713CA03A12",
        "editionName": "VIPe 3 Adobe Presenter Licensed for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65304763CA02A12",
        "editionName": "VIP 2 Select Adobe Technical Suite für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322493CA01A12",
        "editionName": "VIPe 1 Adobe FrameMaker Publishing Server für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324902CA01012",
        "editionName": "Promo VIP 1 InCopy Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322588CA04A12",
        "editionName": "VIP 4 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65304579CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Creative Cloud for teams All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65322504CA02A12",
        "editionName": "VIPe 2 Adobe Dreamweaver für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324952CA01A12",
        "editionName": "VIPe 1 Adobe Dimension Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65325078CA02012",
        "editionName": "Promo VIP 2 Adobe Creative Cloud Pro für Teams All Apps (Multi European Languages)"
      },
      {
        "offerId": "65324849CA03012",
        "editionName": "Promo VIP 3 Dimension Pro für Teams (EU English)"
      },
      {
        "offerId": "65305313CA04A12",
        "editionName": "VIP 4 Select Adobe Lightroom Classic für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324799CA02012",
        "editionName": "Promo VIP 2 Animate / Flash Professional Pro for teams (EU English)"
      },
      {
        "offerId": "65305449CA02A12",
        "editionName": "VIP 2 Select Adobe Dimension für Teams (EU English)"
      },
      {
        "offerId": "65322488CA03A12",
        "editionName": "VIPe 3 Adobe FrameMaker Publishing Server für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305370CA02A12",
        "editionName": "VIP 2 Select Adobe Premiere Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305243CA12A12",
        "editionName": "VIP 12 3 Year Commit Adobe Stock for teams (Large) (Multi EU Languages)"
      },
      {
        "offerId": "65317574CA03A12",
        "editionName": "VIP 3 Select Adobe Substance 3D Collection for Teams (Multi European Languages)"
      },
      {
        "offerId": "65305184CA01A12",
        "editionName": "VIP 1 Adobe Illustrator für Teams (EU English)"
      },
      {
        "offerId": "65324949CA04A12",
        "editionName": "VIPe 4 Adobe Premiere Pro - Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324897CA04A12",
        "editionName": "VIP 4 Adobe Audition Pro für Teams (EU English)"
      },
      {
        "offerId": "65324935CA02012",
        "editionName": "Promo VIP 2 Adobe After Effects Pro für Teams (EU English"
      },
      {
        "offerId": "65324820CA01A12",
        "editionName": "VIPe 1 Adobe After Effects Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322443CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Creative Cloud for Enterprise All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65322708CA04A12",
        "editionName": "VIPe 4 Adobe Captivate für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305599CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Acrobat Sign for SMB (Multi EU Languages)"
      },
      {
        "offerId": "65325070CA02A12",
        "editionName": "VIPe 2 Adobe Creative Cloud Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304886CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Acrobat Standard DC for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324901CA03012",
        "editionName": "Promo VIP 3 Audition Pro for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65324938CA04012",
        "editionName": "Promo VIP 4 InDesign Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324859CA02A12",
        "editionName": "VIPe 2 Adobe Photoshop Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324797CA03A12",
        "editionName": "VIP 3 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
      },
      {
        "offerId": "65305247CA02A12",
        "editionName": "VIP 2 Select Adobe Stock für Teams Large 750 Standard Assets (EU English)"
      },
      {
        "offerId": "65324911CA02A12",
        "editionName": "VIP 2 Adobe Illustrator Pro für Teams (EU English)"
      },
      {
        "offerId": "65324935CA03A12",
        "editionName": "VIP 3 Adobe After Effects Pro für Teams (EU English)"
      },
      {
        "offerId": "65322723CA03A12",
        "editionName": "VIPe 3 Adobe Technical Suite für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324902CA04012",
        "editionName": "Promo VIP 4 InCopy Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322505CA01A12",
        "editionName": "VIPe 1 Adobe Dreamweaver für Enterprise (EU English)"
      },
      {
        "offerId": "65324911CA03012",
        "editionName": "Promo VIP 3 Adobe Illustrator Pro for Teams (EU English)"
      },
      {
        "offerId": "65322656CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit InDesign for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305308CA04A12",
        "editionName": "VIP 4 Select Adobe Lightroom Classic für Teams (EU English)"
      },
      {
        "offerId": "65324849CA02012",
        "editionName": "Promo VIP 2 Dimension Pro für Teams (EU English)"
      },
      {
        "offerId": "65322628CA04A12",
        "editionName": "VIPe 4 Adobe Lightroom Classic für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65317574CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Substance 3D Collection for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65304482CA03A12",
        "editionName": "VIP 3 Select Adobe Audition für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305450CA04A12",
        "editionName": "VIP 4 Select Adobe Dimension für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324933CA01A12",
        "editionName": "VIP 1 Adobe After Effects Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322534CAT2A12",
        "editionName": "Tier 2 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65305184CA04A12",
        "editionName": "VIP 4 Select Adobe Illustrator für Teams (EU English)"
      },
      {
        "offerId": "65305118CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Adobe XD for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322437CA04A12",
        "editionName": "VIPe 4 Adobe RoboHelp Server für Enterprise (EU English)"
      },
      {
        "offerId": "65324782CA03A12",
        "editionName": "VIP 3 Select Adobe XD Pro für Teams (EU English)"
      },
      {
        "offerId": "65304482CA02A12",
        "editionName": "VIP 2 Select Adobe Audition für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305248CA02A12",
        "editionName": "VIP 2 Select Adobe Stock für Teams Small 10 Standard Assets (Multi European Languages)"
      },
      {
        "offerId": "65322427CA03A12",
        "editionName": "VIPe 3 Adobe Audition für Enterprise (EU English)"
      },
      {
        "offerId": "65324776CA03A12",
        "editionName": "VIPe 3 Adobe Lightroom Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322607CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Acrobat Standard DC for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65325064CA03A12",
        "editionName": "VIPe 3 Adobe Creative Cloud Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65305308CA01A12",
        "editionName": "VIP 1 Adobe Lightroom Classic für Teams (EU English)"
      },
      {
        "offerId": "65305507CA01A12",
        "editionName": "VIP 1 Adobe Animate / Flash Professional für Teams (EU English)"
      },
      {
        "offerId": "65322723CA02A12",
        "editionName": "VIPe 2 Adobe Technical Suite für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324823CA02A12",
        "editionName": "VIPe 2 Adobe After Effects Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324820CA02A12",
        "editionName": "VIPe 2 Adobe After Effects Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322537CAT1A12",
        "editionName": "Tier 1 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65305449CA04A12",
        "editionName": "VIP 4 Select Adobe Dimension für Teams (EU English)"
      },
      {
        "offerId": "65322649CA01A12",
        "editionName": "VIPe 1 Acrobat Pro DC für Enterprise (EU English)"
      },
      {
        "offerId": "65322630CA01A12",
        "editionName": "VIPe 1 Adobe Lightroom Classic for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324878CA04A12",
        "editionName": "VIPe 4 Adobe InCopy Pro für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322472CA01A12",
        "editionName": "VIPe 1 Adobe Premiere Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322443CA03A12",
        "editionName": "VIPe 3 Adobe Creative Cloud für Enterprise (EU English)"
      },
      {
        "offerId": "65305370CA03A12",
        "editionName": "VIP 3 Select Adobe Premiere Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322422CA02A12",
        "editionName": "VIPe 2 Adobe Illustrator für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324871CA01012",
        "editionName": "Promo VIP 1 Dimension Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322484CAT2A12",
        "editionName": "Tier 2 Acrobat Sign Solutions for Business, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65324935CA03012",
        "editionName": "Promo VIP 3 Adobe After Effects Pro für Teams (EU English"
      },
      {
        "offerId": "65324903CA03012",
        "editionName": "Promo VIP 3 InCopy Pro for teams (EU English)"
      },
      {
        "offerId": "65315888CA04A12",
        "editionName": "VIP 4 Select Adobe RoboHelp Office für Teams (EU English)"
      },
      {
        "offerId": "65324910CA04012",
        "editionName": "Promo VIP 4 Adobe Illustrator Pro for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65322563CA02A12",
        "editionName": "VIPe 2 Adobe Dimension für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322630CA04A12",
        "editionName": "VIPe 4 Adobe Lightroom Classic für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65304716CA03A12",
        "editionName": "VIP 3 Select Adobe InCopy für Teams (EU English)"
      },
      {
        "offerId": "65322648CA04A12",
        "editionName": "VIPe 4 Adobe Acrobat DC Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65304521CA04A12",
        "editionName": "VIP 4 Adobe Acrobat Pro für Teams (Multi European Language)"
      },
      {
        "offerId": "65322653CA03A12",
        "editionName": "VIPe 3 Adobe InDesign for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322581CA01A12",
        "editionName": "VIPe 1 Adobe Animate/Flash Professional für Enterprise (EU English)"
      },
      {
        "offerId": "65324797CA02012",
        "editionName": "Promo VIP 2 Adobe Premiere Pro - Pro für Teams (EU Englisch)"
      },
      {
        "offerId": "65304579CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Creative Cloud for teams All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65305507CA04A12",
        "editionName": "VIP 4 Select Adobe Animate / Flash Professional für Teams (EU English)"
      },
      {
        "offerId": "65322595CA02A12",
        "editionName": "VIPe 2 Adobe XD für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305507CA03A12",
        "editionName": "VIP 3 Select Adobe Animate / Flash Professional für Teams (EU English)"
      },
      {
        "offerId": "65304763CA01A12",
        "editionName": "VIP 1 Adobe Technical Suite für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322504CA14A12",
        "editionName": "VIPe 14 3 Year Commit Adobe Dreamweaver for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65322607CA04A12",
        "editionName": "VIPe 4 Adobe Acrobat DC Standard für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324935CA02A12",
        "editionName": "VIP 2 Adobe After Effects Pro für Teams (EU English)"
      },
      {
        "offerId": "65304522CA01A12",
        "editionName": "VIP 1 Adobe Acrobat Pro für Teams (EU English)"
      },
      {
        "offerId": "65322723CA01A12",
        "editionName": "VIPe 1 Adobe Technical Suite für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65324857CA03A12",
        "editionName": "VIPe 3 Adobe Photoshop Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324883CA03012",
        "editionName": "Promo VIP 3 Dreamweaver Pro für Teams (EU English)"
      },
      {
        "offerId": "65324867CA01012",
        "editionName": "Promo VIP 1 Photoshop Pro for teams (EU English)"
      },
      {
        "offerId": "65322443CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Creative Cloud for Enterprise All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65324884CA03A12",
        "editionName": "VIP 3 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324771CA03012",
        "editionName": "Promo VIP 3 Lightroom Classic Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322519CA04A12",
        "editionName": "VIPe 4 Adobe Fresco für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305369CA03A12",
        "editionName": "VIP 3 Select Adobe Premiere Pro für Teams (EU English)"
      },
      {
        "offerId": "65304840CA02A12",
        "editionName": "VIP 2 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (EU English)"
      },
      {
        "offerId": "65305247CA04A12",
        "editionName": "VIP 4 Select Adobe Stock für Teams Large 750 Standard Assets (EU English)"
      },
      {
        "offerId": "65322648CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Acrobat Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65304918CA02A12",
        "editionName": "VIP 2 Select Adobe Dreamweaver für Teams (Multi European Languages)"
      },
      {
        "offerId": "65305183CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Illustrator for teams (Multi EU Languages)"
      },
      {
        "offerId": "65305063CA04A12",
        "editionName": "VIP 4 Select Adobe Fresco für Teams (Multi European Languages)"
      },
      {
        "offerId": "65324884CA01A12",
        "editionName": "VIP 1 Adobe Dreamweaver Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304484CA01A12",
        "editionName": "VIP 1 Adobe Audition für Teams (EU English)"
      },
      {
        "offerId": "65304754CA13A12",
        "editionName": "VIP 13 3 Year Commit Captivate for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324826CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Illustrator Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65325078CA12012",
        "editionName": "VIP 12 3 Year Commit Creative Cloud Pro for Teams All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65322725CA02A12",
        "editionName": "VIPe 2 Adobe Technical Suite für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324897CA02A12",
        "editionName": "VIP 2 Adobe Audition Pro für Teams (EU English)"
      },
      {
        "offerId": "65322537CAT4A12",
        "editionName": "Tier 4 Acrobat Sign Solutions for Business, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65324903CA03A12",
        "editionName": "VIP 3 Adobe InCopy Pro für Teams (EU English)"
      },
      {
        "offerId": "65322471CA04A12",
        "editionName": "VIPe 4 Adobe Premiere Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322656CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit InDesign for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65322444CA01A12",
        "editionName": "VIPe 1 Adobe Creative Cloud für Enterprise (EU English)"
      },
      {
        "offerId": "65322607CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Acrobat Standard DC for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324901CA02012",
        "editionName": "Promo VIP 2 Audition Pro for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65305156CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Photoshop for teams (Multi EU Languages)"
      },
      {
        "offerId": "65304840CA04A12",
        "editionName": "VIP 4 Select Adobe Creative Cloud für Teams All Apps mit 10 Adobe Stock Standard Assets (EU English)"
      },
      {
        "offerId": "65322517CA04A12",
        "editionName": "VIPe 4 Adobe Fresco für Enterprise (EU English)"
      },
      {
        "offerId": "65317571CA03A12",
        "editionName": "VIP 3 Select Adobe Substance 3D Collection for Teams (EU English)"
      },
      {
        "offerId": "65324871CA04012",
        "editionName": "Promo VIP 4 Dimension Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322656CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit InDesign for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305243CA14A12",
        "editionName": "VIP 14 3 Year Commit Adobe Stock for teams (Large) (Multi EU Languages)"
      },
      {
        "offerId": "65322443CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Creative Cloud for Enterprise All Apps (Multi EU Languages)"
      },
      {
        "offerId": "65322603CA02A12",
        "editionName": "VIPe 2 Acrobat DC Standard  für Enterprise (EU English)"
      },
      {
        "offerId": "65305343CA03A12",
        "editionName": "VIP 3 Select Adobe FrameMaker für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322429CA14A12",
        "editionName": "VIPe 14 Adobe Audition for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305282CA03A12",
        "editionName": "VIP 3 Select Adobe Stock für Teams Medium 40 Standard Assets (EU English)"
      },
      {
        "offerId": "65324841CA04A12",
        "editionName": "VIPe 4 Adobe Dreamweaver Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65322417CA01A12",
        "editionName": "VIPe 1 Adobe Illustrator für Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322623CA03A12",
        "editionName": "VIPe 3 Adobe After Effects für Enterprise (EU English)"
      },
      {
        "offerId": "65322422CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Illustrator for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65324883CA01A12",
        "editionName": "VIP 1 Adobe Dreamweaver Pro für Teams (EU English)"
      },
      {
        "offerId": "65324785CA03A12",
        "editionName": "VIP 3 Adobe Lightroom Classic Pro für Teams (EU English)"
      },
      {
        "offerId": "65305183CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Illustrator for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324945CA03A12",
        "editionName": "VIPe 3 Adobe Premiere Pro - Pro für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65305156CA02A12",
        "editionName": "VIP 2 Select Adobe Photoshop für Teams (Multi European Languages)"
      },
      {
        "offerId": "65304754CA12A12",
        "editionName": "VIP 12 3 Year Commit Captivate for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322710CA04A12",
        "editionName": "VIPe 4 Adobe Captivate für Enterprise (EU English)"
      },
      {
        "offerId": "65322708CA03A12",
        "editionName": "VIPe 3 Adobe Captivate für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322558CA01A12",
        "editionName": "VIP 1 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65322434CAT2A12",
        "editionName": "Tier 2 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65324867CA04A12",
        "editionName": "VIP 4 Adobe Photoshop Pro für Teams (EU English)"
      },
      {
        "offerId": "65324911CA04012",
        "editionName": "Promo VIP 4 Adobe Illustrator Pro for Teams (EU English)"
      },
      {
        "offerId": "65305158CA03A12",
        "editionName": "VIP 3 Select Adobe Photoshop für Teams (EU English)"
      },
      {
        "offerId": "65304521CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Acrobat Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65324867CA03A12",
        "editionName": "VIP 3 Adobe Photoshop Pro für Teams (EU English)"
      },
      {
        "offerId": "65322434CAT4A12",
        "editionName": "Tier 4 Acrobat Sign Solutions for Enterprise, AWS (Multi EU Languages)"
      },
      {
        "offerId": "65324933CA04A12",
        "editionName": "VIP 4 Adobe After Effects Pro für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322638CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Photoshop for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305156CA01A12",
        "editionName": "VIP 1 Adobe Photoshop für Teams (Multi European Languages)"
      },
      {
        "offerId": "65322422CA04A12",
        "editionName": "VIPe 4 Adobe Illustrator für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322472CA02A12",
        "editionName": "VIPe 2 Adobe Premiere Pro für Enterprise (EU English)"
      },
      {
        "offerId": "65324883CA02A12",
        "editionName": "VIP 2 Adobe Dreamweaver Pro für Teams (EU English)"
      },
      {
        "offerId": "65305410CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit InDesign for teams (Multi EU Languages)"
      },
      {
        "offerId": "65305248CA12A12",
        "editionName": "VIP 12 3 Year Commit Adobe Stock for teams (Small) (Multi EU Languages)"
      },
      {
        "offerId": "65322648CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Acrobat Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305118CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Adobe XD for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322713CA02A12",
        "editionName": "VIPe 2 Adobe Presenter Licensed for Enterprise (EU Englisch)"
      },
      {
        "offerId": "65322623CA02A12",
        "editionName": "VIPe 2 Adobe After Effects für Enterprise (EU English)"
      },
      {
        "offerId": "65323478CA03012",
        "editionName": "VIPe 3 Adobe Express für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65324802CA03012",
        "editionName": "Promo VIP 3 Select Animate / Flash Professional Pro for teams (Multi EU Languages)"
      },
      {
        "offerId": "65322534CAT3A12",
        "editionName": "Tier 3 Acrobat Sign Solutions for Enterprise, Azure (Multi EU Languages)"
      },
      {
        "offerId": "65322698CA02A12",
        "editionName": "VIPe 2 Adobe RoboHelp Office für Enterprise (Multi European Languages)"
      },
      {
        "offerId": "65322471CA14A12",
        "editionName": "VIPe 14 Adobe Premiere Pro for Enterprise (Multi EU Languages)"
      },
      {
        "offerId": "65305285CA14A12",
        "editionName": "VIP 14 3 Year Commit Adobe Stock for teams (Medium) (Multi EU Languages)"
      },
      {
        "offerId": "65304841CA14A12",
        "editionName": "VIP 14 Select 3 Year Commit Creative Cloud for teams All Apps with Adobe Stock (Multi EU Languages)"
      },
      {
        "offerId": "65317574CA12A12",
        "editionName": "VIP 12 Select 3 Year Commit Substance 3D Collection for Teams (Multi EU Languages)"
      },
      {
        "offerId": "65304607CA02A12",
        "editionName": "VIP 2 Select Adobe After Effects für Teams (EU English)"
      },
      {
        "offerId": "65305599CA13A12",
        "editionName": "VIP 13 Select 3 Year Commit Acrobat Sign for SMB (Multi EU Languages)"
      }
    ]

    const foundEdition = adobeEditions.find(edition => edition.offerId == offerId)
    if (foundEdition?.editionName != undefined) {
      return foundEdition.editionName
    } else {
      return false
    }
  }

  const migrationModes = [
    {
      id: 1,
      name: "Subscription Upgrade",
      steps: [
        { id: 0, task: "1. Select the Marketplace Company" },
        { id: 1, task: "2. Select the current Subscription" },
        { id: 2, task: "3. Select the Billing User" },
        { id: 3, task: "4. New Quantity" },
        { id: 4, task: "5. Select the Reseller Company" },
        { id: 5, task: "6. Select the Creation Date" },
        { id: 6, task: "7. Summary" }
      ],
      description: [
        "Based on an existing Marketplace subscription",
        "Subscription can be active or canceled",
        "Preferred if there is a booking for the Adobe product in the Marketplace company"
      ]
    },
    {
      id: 2,
      name: "Subscription Order",
      steps: [
        { id: 0, task: "1. Select the Marketplace Company" },
        { id: 1, task: "2. Select the Product" },
        { id: 2, task: "3. Select the Editon & Frequency" },
        { id: 3, task: "4. Select the Billing User" },
        { id: 4, task: "5. Select the Adobe Customer" },
        { id: 5, task: "6. Select the Adobe Customer Order" },
        { id: 6, task: "7. Quantity" },
        { id: 7, task: "8. Select the Reseller Company" },
        { id: 8, task: "9. Select the Creation Date" },
        { id: 9, task: "10. Summary" }
      ],
      description: [
        "Based on an existing Adobe API Order",
        "Preferred if the Marketplace company does not yet have a booking for the Adobe product"
      ]
    },
  ]

  const resellerCompanies = [
    {
      id: 0,
      name: "CANCOM BU Hansa",
      uuid: "f189a7c3-f5a0-496c-9e37-d3b99f9c780c",
      salesUser: "CFR1313_9987561",
      salesCompany: "CFR1313_998756"
    },
    {
      id: 1,
      name: "CANCOM BU Software Public Sector",
      uuid: "fb35bac8-2589-45e3-9d24-249fc88f6e47",
      salesUser: "CFR1313_2255301",
      salesCompany: "CFR1313_2132957"
    },
    {
      id: 2,
      name: "CANCOM BU Education",
      uuid: "1fb84261-5339-41df-9e13-a603c6cedf6e",
      salesUser: "CFR1313_2255295",
      salesCompany: "CFR1313_2132951"
    },
    {
      id: 3,
      name: "CANCOM BU Managed Services",
      uuid: "701d1545-5b55-45f0-a251-21f50851df34",
      salesUser: "CFR1313_2255287",
      salesCompany: "CFR1313_2132947"
    },
    {
      id: 4,
      name: "CANCOM BU Marketplace",
      uuid: "4a5cc2ea-dc2f-467d-b681-867e635e1c65",
      salesUser: "CFR1313_2255285",
      salesCompany: "CFR1313_2132945"
    },
    {
      id: 5,
      name: "CANCOM BU Österreich",
      uuid: "8eea1f3a-1f05-4606-8612-de521ef00471",
      salesUser: "CFR1313_2254711",
      salesCompany: "CFR1313_2132579"
    },
    {
      id: 6,
      name: "CANCOM BU Ost",
      uuid: "7ee8aad1-6403-4a58-9edd-a45a43afb1f1",
      salesUser: "CFR1313_2254709",
      salesCompany: "CFR1313_2132577"
    },
    {
      id: 7,
      name: "CANCOM BU Nord",
      uuid: "2bca1f9f-8854-42d8-a53d-c375c9fb9941",
      salesUser: "CFR1313_2254707",
      salesCompany: "CFR1313_2132575"
    },
    {
      id: 8,
      name: "CANCOM BU West",
      uuid: "f07e22d3-2d32-499b-b005-8a7d3007638e",
      salesUser: "CFR1313_2254689",
      salesCompany: "CFR1313_2132565"
    },
    {
      id: 9,
      name: "CANCOM BU Südwest",
      uuid: "bce7365b-5e35-489d-98e8-022fd865d337",
      salesUser: "CFR1313_2254685",
      salesCompany: "CFR1313_2132561"
    },
    {
      id: 10,
      name: "CANCOM BU Süd",
      uuid: "06944e19-1571-4e08-bd31-07da3f223475",
      salesUser: "CFR1313_2254681",
      salesCompany: "CFR1313_2132557"
    },
    {
      id: 11,
      name: "CANCOM BU Mitte",
      uuid: "4e6d4757-27be-4665-8471-46598641a9ae",
      salesUser: "CFR1313_2254703",
      salesCompany: "CFR1313_2088143"
    },
    {
      id: 12,
      name: "CANCOM Direct Jettingen-Scheppach",
      uuid: "35938b74-a698-4862-a022-5451794470ab",
      salesUser: "CFR1313_2230897_1",
      salesCompany: "CFR1313_2073803"
    },
    {
      id: 13,
      name: "CANCOM Direct Kempten",
      uuid: "5697d8eb-562c-4b8c-a111-65619dac7d8c",
      salesUser: "CFR1313_2230827",
      salesCompany: "CFR1313_2073799"
    },
    {
      id: 14,
      name: "CANCOM Direct FFM",
      uuid: "d369c923-b477-44f8-ac94-99a9a8471720",
      salesUser: "CFR1313_2254715",
      salesCompany: "CFR1313_2132583"
    },
    {
      id: 15,
      name: "CANCOM BU Hannover",
      uuid: "32a96058-87ec-44ee-afe5-539d00f6d6f8",
      salesUser: "CFR1313_2254715_1",
      salesCompany: "CFR1313_2132583_1"
    },
    {
      id: 16,
      name: "CANCOM BU Direct Digital",
      uuid: "771e731b-4916-4efc-891e-5f226b5dc30a",
      salesUser: "CFR1313_2254716",
      salesCompany: "CFR1313_2132584"
    },
    {
      id: 17,
      name: "CANCOM BU SuL",
      uuid: "0a3f6180-5765-40d1-b385-a4595f8bd058",
      salesUser: "CFR1313_2254717",
      salesCompany: "CFR1313_2132585"
    }
  ]

  const { isLoggedIn, login, logout, user, relogin } = useAuth();
  const [mpCompaniesData, setMPCompaniesData] = useState([])
  const [mpSubscriptionsData, setMPSubscriptionsData] = useState([])
  const [mpProductData, setMPProductData] = useState([])
  const [mpCompanyUserData, setMPCompanyUserData] = useState([])
  const [adobeCustomerData, setAdobeCustomerData] = useState([])
  const [adobeCustomerOrderData, setAdobeCustomerOrderData] = useState([])
  const [loading, setLoading] = useState(false);
  const [companyFilterValue, setCompanyFilterValue] = useState("---");
  const [subscriptionFilterValue, setSubscriptionFilterValue] = useState("");
  const [productFilterValue, setProductFilterValue] = useState("");
  const [adobeCustomerFilterValue, setAdobeCustomerFilterValue] = useState("");
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingAdobeCustomers, setLoadingAdobeCustomers] = useState(true);
  const [loadingAdobeCustomerOrders, setLoadingAdobeCustomerOrders] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [migrationMode, setMigrationMode] = useState(0);
  const [currentConfig, setCurrentConfig] = useState({
    "company": {
      "uuid": null,
      "externalId": null,
      "name": null,
      "status": null,
      "resellerCompany": -1,
    },
    "subscription": {
      "arrayIndex": -1,
      "id": null,
      "orderId": null,
      "editionName": null,
      "paymentPlanUUID": null,
      "quantity": 0,
      "creationDate": 0,
      "userUUID": null,
      "unit": null,
      "discountId": null,
      "adobeCustomerId": null,
      "adobeResellerId": "1000002433",
      "poNumber": null,
      "externalAccountId": null
    },
    "quantity": 0,
    "resellerCompany": -1,
    "transferId": "NA",
    "creationDate": 0,
    "user": {
      "uuid": null,
      "externalId": null,
      "email": null,
      "activated": null
    },
    "externalId": null,
    "orderStatus": "ACTIVE",
    "product": {
      "productName": null,
      "productId": null,
      "editionName": null,
      "editionId": null,
      "pricingPlanUUID": null
    }
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  console.log(migrationMode)
  console.log(currentConfig)
  // console.log(currentConfig)
  const handleCompanyInput = (e) => {
    e.preventDefault();
    e.target.value == ""
      ? setCompanyFilterValue("---")
      : setCompanyFilterValue(e.target.value);
  };

  const handleSubscriptionInput = (e) => {
    e.preventDefault();
    setSubscriptionFilterValue(e.target.value);
  };

  const handleCurrentConfigChange = (changesArray) => {
    console.log(changesArray)
    const tempCurrentConfig = { ...currentConfig }

    changesArray.forEach((change) => {
      const [property, key, value] = change;

      if (property === null) {
        tempCurrentConfig[key] = value;
      } else if (tempCurrentConfig[property]) {
        tempCurrentConfig[property][key] = value;
      }
    })
    setCurrentConfig(tempCurrentConfig);
  };

  const changeStep = (up) => {
    if (up) {

      if (migrationMode - 1 == 0 && migrationModes[migrationMode - 1].steps[currentStep].id == 1) {
        console.log(mpSubscriptionsData)
        handleCurrentConfigChange([
          ["subscription", "vb1", (mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.customAttributes.filter(ca => ca.name == "Fachvertrieb")[0]?.value ? mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.customAttributes.filter(ca => ca.name == "Fachvertrieb")[0]?.value : null)],
          // ["subscription", "adobeResellerId", (mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.customAttributes.filter(ca => ca.name == "ADOBE_RESELLER_ID")[0].value ? mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.customAttributes.filter(ca => ca.name == "ADOBE_RESELLER_ID")[0].value : null)],
          ["subscription", "adobeCustomerId", (mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.customAttributes.filter(ca => ca.name == "ADOBE_CUSTOMER_ACCOUNT_ID")[0].value ? mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.customAttributes.filter(ca => ca.name == "ADOBE_CUSTOMER_ACCOUNT_ID")[0].value : null)],
          ["subscription", "discountId", (mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.discountId ? mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.discountId : null)],
          ["subscription", "unit", (mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.orderLines.filter(item => item.type == "ITEM"))[0].unit],
          ["subscription", "editionName", (mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.orderLines.filter(item => item.type == "ITEM"))[0].editionName],
          ["subscription", "id", mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].subscription.id],
          ["subscription", "orderId", mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].subscription.order.id],
          ["subscription", "paymentPlanUUID", mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.paymentPlan.uuid],
          ["subscription", "quantity", (mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.orderLines.filter(item => item.type == "ITEM"))[0].quantity],
          ["subscription", "creationDate", mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].subscription.creationDate],
          ["subscription", "userUUID", mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.user.id],
          ["subscription", "externalAccountId", mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].subscription.externalAccountId],
          [null, "creationDate", `${new Date(mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].subscription.creationDate).getFullYear()}-${(new Date(mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].subscription.creationDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].subscription.creationDate).getDate().toString().padStart(2, '0')}`]
        ])

      }
      setCurrentStep((prev) => prev + 1)
    } else {
      if (currentStep == 0 && (queryParams.get('subscriptionId') || queryParams.get('customerId'))) {

        window.location.href = "/Migration";
      }
      currentStep > 0
        ? setCurrentStep((prev) => prev - 1)
        : setMigrationMode(0)

    }
    console.log("currentStep: " + currentStep)
  }

  // Subscription Order Skip
  useEffect(() => {
    const loadPost = async () => {
      let mpCompanies
      let customerId
      let subscriptionId
      try {
        setLoading(true)
        customerId = queryParams.get('customerId');
        subscriptionId = queryParams.get('subscriptionId');

        setMigrationMode(2)
        // console.log(customerId + " - " + subscriptionId)
        handleCurrentConfigChange([
          ["subscription", "adobeCustomerId", customerId],
          ["subscription", "externalAccountId", subscriptionId]
        ])
        setAdobeCustomerFilterValue(customerId)

        setLoading(false)
      } catch (err) {
        if (mpCompanies?.message == "jwt expired") {
          jwtExpired()
        }
      }
    }
    if (queryParams.get('subscriptionId') && queryParams.get('customerId') && migrationMode == 0) {

      loadPost();
    }
  }, [queryParams]);

  // Get Companies
  useEffect(() => {
    const loadPost = async () => {
      let mpCompanies
      try {
        setLoading(true)
        mpCompanies = await getMPCompanies(user)

        setMPCompaniesData(mpCompanies)

        setLoading(false)
      } catch (err) {
        if (mpCompanies?.message == "jwt expired") {
          jwtExpired()
        }
      }
    }
    if (isLoggedIn.state == true) {

      loadPost();
    }
  }, [isLoggedIn.state, user]);

  // Get Subscriptions
  useEffect(() => {
    const loadPost = async () => {

      let mpSubscriptions
      let mpCompanyAssociations
      let mpCompanyExternalId
      try {

        setLoadingSubscriptions(true)
        mpSubscriptions = await getMPSubscriptions(user, currentConfig.company.uuid)
        mpCompanyAssociations = await getCompanyAssociations(user, currentConfig.company.uuid)

        console.log(mpCompanyAssociations.data.content)
        handleCurrentConfigChange([
          ["company", "resellerCompany", (resellerCompanies.filter(company => company.uuid == mpCompanyAssociations.data.content[0].resellerCompany.id))[0].id],
          [null, "resellerCompany", (resellerCompanies.filter(company => company.uuid == mpCompanyAssociations.data.content[0].resellerCompany.id))[0].id]
        ])

        // const tempCurrentConfig = currentConfig
        // tempCurrentConfig.company.resellerCompany = (resellerCompanies.filter(company => company.uuid == mpCompanyAssociations.data.content[0].resellerCompany.id))[0].id
        // tempCurrentConfig.resellerCompany = (resellerCompanies.filter(company => company.uuid == mpCompanyAssociations.data.content[0].resellerCompany.id))[0].id
        // setCurrentConfig(tempCurrentConfig)
        console.log(mpSubscriptions)

        if (currentConfig.company.externalId == null) {

          mpCompanyExternalId = await postMPCompanyExternalId(user, currentConfig.company.uuid)
        }

        setMPSubscriptionsData(mpSubscriptions)

        setLoadingSubscriptions(false)
      } catch (err) {
        console.log(err)
        if (mpCompanyAssociations?.message || mpSubscriptions?.message || mpCompanyExternalId?.message == "jwt expired") {
          jwtExpired()
        }
      }

    }
    if (currentConfig.company.uuid != null && currentStep == 1 && migrationMode == 1) {

      loadPost()
    }
  }, [currentStep]);

  // Get Products
  useEffect(() => {
    const loadPost = async () => {

      let mpProducts
      let mpCompanyUser
      let mpCompanyAssociations
      let mpCompanyExternalId
      try {

        setLoadingProducts(true)
        mpProducts = await getMPProducts(user)
        console.log(mpProducts)
        setMPProductData(mpProducts)
        setLoadingProducts(false)
        setLoadingUsers(true)
        mpCompanyUser = await getMPCompanyUser(user, currentConfig.company.uuid)
        mpCompanyUser = mpCompanyUser.data.content.filter(member => member.user.activated == true)
        console.log(mpCompanyUser)
        setMPCompanyUserData(mpCompanyUser)

        const now = new Date();
        const formattedDateTime = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}`;
        const randomFourDigitNumber = Math.floor(1000 + Math.random() * 9000);

        mpCompanyAssociations = await getCompanyAssociations(user, currentConfig.company.uuid)

        console.log(mpCompanyAssociations.data.content)
        let resellerCompanyCompanyId
        let resellerCompanyId
        try {
          resellerCompanyCompanyId = (resellerCompanies?.filter(company => company?.uuid == mpCompanyAssociations?.data?.content[0]?.resellerCompany?.id))[0]?.id
          resellerCompanyId = (resellerCompanies.filter(company => company.uuid == mpCompanyAssociations.data.content[0].resellerCompany.id))[0].id
        } catch (err) {
          resellerCompanyId = "-1"
          resellerCompanyCompanyId = "-1"
        }

        if (currentConfig.company.externalId == null) {

          mpCompanyExternalId = await postMPCompanyExternalId(user, currentConfig.company.uuid)

          handleCurrentConfigChange([
            ["company", "resellerCompany", resellerCompanyCompanyId],
            ["company", "externalId", mpCompanyExternalId.data.externalId],
            [null, "resellerCompany", resellerCompanyId],
            ["user", "email", mpCompanyUser[0]?.user?.email],
            ["user", "uuid", mpCompanyUser[0]?.user?.uuid],
            ["user", "externalId", mpCompanyUser[0]?.user?.externalId],
            ["user", "activated", mpCompanyUser[0]?.user?.activated],
            [null, "externalId", `AdobeMigration_${formattedDateTime}_New_${randomFourDigitNumber}`]
          ])
        } else {
          handleCurrentConfigChange([
            ["company", "resellerCompany", resellerCompanyCompanyId],
            [null, "resellerCompany", resellerCompanyId],
            ["user", "email", mpCompanyUser[0]?.user?.email],
            ["user", "uuid", mpCompanyUser[0]?.user?.uuid],
            ["user", "externalId", mpCompanyUser[0]?.user?.externalId],
            ["user", "activated", mpCompanyUser[0]?.user?.activated],
            [null, "externalId", `AdobeMigration_${formattedDateTime}_New_${randomFourDigitNumber}`]
          ])
        }

        setLoadingUsers(false)
      } catch (err) {
        console.log(err)
        if (mpProducts?.message == "jwt expired") {
          jwtExpired()
        }
      }

    }
    if (currentConfig.company.uuid != null && currentStep == 1 && migrationMode == 2) {

      loadPost()
    }
  }, [currentStep]);

  // Get Adobe Customers
  useEffect(() => {
    const loadPost = async () => {

      let adobeCustomers
      try {

        setLoadingAdobeCustomers(true)
        adobeCustomers = await getAdobeCustomers(user)

        setAdobeCustomerData(adobeCustomers)

        setLoadingAdobeCustomers(false)
      } catch (err) {
        console.log(err)
        if (adobeCustomers?.message == "jwt expired") {
          jwtExpired()
        }
      }

    }
    if (currentStep == 4 && migrationMode == 2) {

      loadPost()
    }
  }, [currentStep]);

  // Get Adobe Customer Orders
  useEffect(() => {
    const loadPost = async () => {

      let adobeCustomerOrders
      try {

        setLoadingAdobeCustomerOrders(true)
        adobeCustomerOrders = await getAdobeCustomer(user, currentConfig.subscription.adobeCustomerId, true)
        console.log(adobeCustomerOrders)
        setAdobeCustomerOrderData(adobeCustomerOrders)

        adobeCustomerOrders.data.orders.items.map(orders => (
          orders.lineItems.filter((lineItem) => {
            return lineItem
          })))
          .map((orders) =>
            orders.map(order =>
              console.log(order.offerId)
            )
          )


        setLoadingAdobeCustomerOrders(false)
      } catch (err) {
        console.log(err)
        if (adobeCustomerOrders?.message == "jwt expired") {
          jwtExpired()
        }
      }

    }
    if (currentStep == 5 && migrationMode == 2 && currentConfig.subscription.adobeCustomerId != null) {

      loadPost()
    }
  }, [currentStep]);

  // Subscription Selected
  useEffect(() => {
    const loadPost = async () => {

      let mpCompanyUser

      try {
        setLoadingUsers(true)
        mpCompanyUser = await getMPCompanyUser(user, currentConfig.company.uuid)
        console.log(mpCompanyUser.data.content)
        mpCompanyUser = mpCompanyUser.data.content
        setMPCompanyUserData(mpCompanyUser)
        console.log(mpCompanyUser)
        const now = new Date();
        const formattedDateTime = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}`;

        const randomFourDigitNumber = Math.floor(1000 + Math.random() * 9000);
        handleCurrentConfigChange([
          ["user", "email", mpCompanyUser[0]?.user?.email],
          ["user", "uuid", mpCompanyUser[0]?.user?.uuid],
          ["user", "externalId", mpCompanyUser[0]?.user?.externalId],
          ["user", "activated", mpCompanyUser[0]?.user?.activated],
          [null, "externalId", `AdobeMigration_${formattedDateTime}_Upg_${randomFourDigitNumber}`]
        ])
        console.log("externalId")

        // setCurrentConfig(newCurrentConfig)
        setLoadingUsers(false)
      } catch (err) {
        console.log(err)
        if (mpCompanyUser?.message == "jwt expired") {
          jwtExpired()
        }
      }
    }
    if (currentConfig.subscription.id != null || migrationMode == 2) {

      loadPost()
    }
  }, [currentConfig.subscription.id, currentConfig.subscription.paymentPlanUUID]);

  // User External Id
  useEffect(() => {
    const loadPost = async () => {
      let mpUserExternalId
      try {
        if (currentConfig.user.externalId == null) {

          mpUserExternalId = await postMPUserExternalId(user, currentConfig.user.uuid)
          handleCurrentConfigChange([
            ["user", "externalId", currentConfig.user.uuid]
          ])
        }
      } catch (err) {
        if (mpUserExternalId?.message == "jwt expired") {
          jwtExpired()
        }
      }
    }
    if (currentConfig.user.uuid != null) {

      loadPost()
    }
  }, [currentConfig.user.uuid]);

  // Download
  const downloadFile = (migrationMode) => {

    const convertToCSV = (data) => {
      const header = Object.keys(data[0]).join(',');
      const rows = data.map((row) => Object.values(row).join(','));
      return `${header}\n${rows.join('\n')}`;
    };

    console.log("download 1")
    let discount
    if (currentConfig.subscription.discountId == null) {

      discount = ""
    } else {

      discount = currentConfig.subscription.discountId
    }
    const downloadFileData = [{
      // "EXTERNAL_ID": currentConfig.externalId, "EDITION_PRICING_UUID": currentConfig.subscription.paymentPlanUUID, "COMPANY_EXTERNAL_ID": currentConfig.company.externalId, "PURCHASED_BY_EXTERNAL_ID": currentConfig.user.externalId, "PRICING_UNIT": currentConfig.subscription.unit, "UNITS": currentConfig.quantity, "DISCOUNT_CODE": discount, "ORDER_STATUS": currentConfig.orderStatus, "SALES_REP_EXTERNAL_ID": resellerCompanies[currentConfig.resellerCompany].salesUser, "SALES_REP_COMPANY_EXTERNAL_ID": resellerCompanies[currentConfig.resellerCompany].salesCompany, "START_DATE": currentConfig.creationDate + "T12:00:00+00:00", "ISV:TRANSFER_ID": currentConfig.transferId, "ISV:VIP_MEMBERSHIP_ID": currentConfig.subscription.adobeCustomerId, "ISV:ADOBE_RESELLER_ID": currentConfig.subscription.adobeResellerId, "CUSTOM_ATTRIBUTE:Fachvertrieb": currentConfig.subscription.vb1, "CUSTOM_ATTRIBUTE:PONumber": currentConfig.subscription.poNumber
      "EXTERNAL_ID": currentConfig.externalId, "EDITION_PRICING_UUID": currentConfig.subscription.paymentPlanUUID, "COMPANY_EXTERNAL_ID": currentConfig.company.externalId, "PURCHASED_BY_EXTERNAL_ID": currentConfig.user.externalId, "PRICING_UNIT": currentConfig.subscription.unit, "UNITS": currentConfig.quantity, "DISCOUNT_CODE": discount, "ORDER_STATUS": currentConfig.orderStatus, "SALES_REP_EXTERNAL_ID": resellerCompanies[currentConfig.resellerCompany].salesUser, "SALES_REP_COMPANY_EXTERNAL_ID": resellerCompanies[currentConfig.resellerCompany].salesCompany, "START_DATE": currentConfig.creationDate + "T12:00:00+00:00", "ISV:TRANSFER_ID": "Dummy", "ISV:VIP_MEMBERSHIP_ID": "Dummy", "ISV:VIP_SUBSCRIPTION_ID": currentConfig.subscription.externalAccountId, "ISV:ADOBE_RESELLER_ID": currentConfig.subscription.adobeResellerId, "CUSTOM_ATTRIBUTE:Fachvertrieb": currentConfig.subscription.vb1, "CUSTOM_ATTRIBUTE:PONumber": currentConfig.subscription.poNumber
    }]
    const csvData = convertToCSV(downloadFileData);

    // Create a Blob containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a link element to trigger the download
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'ENTITLEMENTS.csv';

    // Append the link to the body and trigger the click event
    document.body.appendChild(a);
    a.click();

    // Remove the link from the body
    document.body.removeChild(a);


    if (migrationMode == 2) {
      console.log("download")
    }
  }
  const resetMigrationBuilder = () => {
    setCurrentConfig({
      "company": {
        "uuid": null,
        "externalId": null,
        "name": null,
        "status": null,
        "resellerCompany": -1,
      },
      "subscription": {
        "arrayIndex": -1,
        "id": null,
        "orderId": null,
        "editionName": null,
        "paymentPlanUUID": null,
        "quantity": 0,
        "creationDate": 0,
        "userUUID": null,
        "unit": null,
        "discountId": null,
        "adobeCustomerId": null,
        "adobeResellerId": "1000002433",
        "poNumber": null,
        "externalAccountId": null
      },
      "quantity": 0,
      "resellerCompany": -1,
      "transferId": "NA",
      "creationDate": 0,
      "user": {
        "uuid": null,
        "externalId": null,
        "email": null,
        "activated": null
      },
      "externalId": null,
      "orderStatus": "ACTIVE",
      "product": {
        "productName": null,
        "productId": null,
        "editionName": null,
        "editionId": null,
        "pricingPlanUUID": null
      }
    });
    setCurrentStep(0)
    setMigrationMode(0)
  }

  const jwtExpired = () => {
    relogin()
    return <section class="flex-grow">Loading Migration Informations...</section>
  }


  return (
    !isLoggedIn.state
      ? isLoggedIn.loading
        ? <section class="flex-grow"></section>
        : <Navigate replace to="/" />
      : loading
        ? <section class="flex-grow">Loading Migration Informations...</section>
        : mpCompaniesData.state
          ? (
            <section class="w-11/12 lg:w-10/12 relative flex-grow mx-auto flex flex-col text-left">
              <section onClick={() => resetMigrationBuilder} class="text-2xl font-medium my-4 text-center">Adobe Migration</section>

              {migrationMode === 0
                ?
                <section class="flex w-full">
                  {migrationModes.map((migrationMode) => (
                    <section onClick={() => { setMigrationMode(migrationMode.id) }} class="hover:border-red-500 flex flex-col m-2 bg-gray-200 pb-10 rounded-lg w-1/2 p-4 text-center font-medium text-xl border-2 border-gray-400">
                      {migrationMode.name}
                      <section class="font-normal text-lg text-left pl-8">
                        {migrationMode.description.map(line =>
                          <li>{line}</li>
                        )}
                      </section>
                    </section>
                  )
                  )}
                </section>
                :
                <section>
                  {/* Header 2 */}
                  <section class="text-center font-medium text-xl w-full border-b border-red-500">
                    {migrationModes[migrationMode - 1].name}
                  </section>
                  <section class="flex w-full">
                    {/* Left Panel */}
                    <section class={` w-full flex flex-col m-2 bg-gray-200 pb-10 rounded-lg p-4`}>
                      <section class="flex mb-2">
                        <button class="my-auto border-2 rounded-md py-2 px-3 bg-gray-300 border-[#DC0029]" onClick={() => changeStep(false)}>
                          Back
                        </button>
                        <section class="ml-5 text-lg font-medium my-auto">{migrationModes[migrationMode - 1].steps[currentStep].task}</section>
                        <button hidden={migrationMode == 1 && currentStep == 6 || migrationMode == 2 && currentStep == 9 && currentConfig.externalId != null ? true : false} class="ml-auto my-auto border-2 rounded-md py-2 px-3 bg-gray-300 border-[#DC0029] disabled:border-[#e8b1bb] disabled:bg-white" disabled={
                          migrationMode == 1
                            ? currentStep == 0 && currentConfig.company.uuid != null
                              ? false : currentStep == 1 && currentConfig.subscription.arrayIndex != -1
                                ? false : currentStep == 2 && currentConfig.user.uuid != null
                                  ? false : currentStep == 3 && currentConfig.quantity != 0
                                    ? false : currentStep == 4 && currentConfig.resellerCompany != -1
                                      ? false : currentStep == 5 && currentConfig.creationDate != 0
                                        ? false
                                        : true
                            : migrationMode == 2
                              ? currentStep == 0 && currentConfig.company.uuid != null
                                ? false : currentStep == 1 && currentConfig.product.productId != null
                                  ? false : currentStep == 2 && currentConfig.product.pricingPlanUUID != null
                                    ? false : currentStep == 3 && currentConfig.user.uuid != 0
                                      ? false : currentStep == 4 && currentConfig.subscription.adobeCustomerId != null
                                        ? false : currentStep == 5 && currentConfig.subscription.externalAccountId != null
                                          ? false : currentStep == 6 && currentConfig.subscription.quantity != null
                                            ? false : currentStep == 7 && currentConfig.resellerCompany != -1
                                              ? false : currentStep == 8 && currentConfig.creationDate != 0
                                                ? false
                                                : true
                              : true
                        } onClick={() => changeStep(true)}>
                          Continue
                        </button>
                        <button
                          hidden={migrationMode == 1 && currentStep == 6 || migrationMode == 2 && currentStep == 9 && currentConfig.externalId != null ? false : true}
                          disabled={
                            currentConfig.externalId?.length < 30
                              ? currentConfig.quantity != null
                                ? true
                                : false
                              : false
                          }
                          class="ml-auto my-auto border-2 rounded-md py-2 px-3 bg-gray-300 border-[#DC0029] disabled:border-[#e8b1bb] disabled:bg-white"
                          onClick={() => downloadFile(migrationMode)}
                        >
                          Download
                        </button>
                      </section>
                      <section class="text-center font-medium text-xl w-full border-b my-4 border-red-500" />
                      {/* 1. Search Marketplace Company */}
                      {migrationModes[migrationMode - 1].steps[currentStep].id == 0 &&
                        <section class="flex flex-col">
                          <section class="flex py-2">
                            <BiSearchAlt size={25} class="my-auto mx-4" />
                            <input
                              type="text"
                              placeholder="Search Company... (min. 3 letters)"
                              onChange={handleCompanyInput}
                              value={companyFilterValue == "---" ? "" : companyFilterValue}
                              class="px-2 py-1 rounded-md w-72 border-4 border-gray-300"
                            />
                            <section class="ml-auto px-2 py-2 rounded-md font-medium">
                              {currentConfig.company.name == null ? "No Company selected" : currentConfig.company.name}
                            </section>
                          </section>
                          <table class=" text-sm text-left text-black">
                            <thead class="text-s border-b-2 border-[#DC0029] text-gray-700 uppercase bg-gray-300">
                              <tr key="header">
                                <th scope="col" class="px-6 py-3">
                                  Company Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                  Company Id
                                </th>
                                <th scope="col" class="px-6 py-3 hidden lg:block">
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody class="z-0">
                              {companyFilterValue.length > 2
                                ? mpCompaniesData.data.filter((mpCompany) => {
                                  if (mpCompany.name != null) {

                                    if (mpCompany.uuid.includes(companyFilterValue) || mpCompany.name.toLocaleLowerCase().includes(companyFilterValue.toLocaleLowerCase())) {
                                      return mpCompany
                                    }
                                  }
                                }).map((mpCompany) =>
                                  // <tr class={`${currentConfig.company.uuid === mpCompany.uuid ? "bg-red-300" : "hover:bg-gray-300"} border-b bg-gray-200 border-[#DC0029] `} onClick={() => setCurrentConfig({ ...currentConfig, company: { name: mpCompany.name, uuid: mpCompany.uuid, status: mpCompany.status, externalId: mpCompany.externalId } })} key={mpCompany.uuid}>
                                  <tr class={`${currentConfig.company.uuid === mpCompany.uuid ? "bg-red-300" : "hover:bg-gray-300"} border-b bg-gray-200 border-[#DC0029] `} onClick={() => handleCurrentConfigChange([["company", "name", mpCompany.name], ["company", "uuid", mpCompany.uuid], ["company", "status", mpCompany.status], ["company", "externalId", mpCompany.externalId]])} key={mpCompany.uuid}>
                                    <td class="px-6 py-4">
                                      <section class="hover:underline hover:decoration-[#DC0029]">{mpCompany.name}</section>
                                    </td>
                                    <td class="px-6 py-4">
                                      {mpCompany.uuid}
                                    </td>
                                    <td class={`px-6 py-4 hidden lg:block
                            ${mpCompany.status == "ACTIVE"
                                        ? "text-green-700"
                                        : "text-[#DC0029]"
                                      } uppercase
                            `}>
                                      {mpCompany.status}
                                    </td>
                                  </tr>
                                )
                                : ""
                              }

                            </tbody>
                          </table>
                        </section>
                      }
                      {/* Subscription Upgrade */}
                      {/* 2. Search Subscription */}
                      {migrationMode - 1 == 0 && migrationModes[migrationMode - 1].steps[currentStep].id == 1 &&
                        <section class="flex flex-col">
                          <section class="flex py-2">
                            <BiSearchAlt size={25} class="my-auto mx-4" />
                            <input
                              type="text"
                              placeholder="Search Subscription... (min. 3 letters)"
                              onChange={handleSubscriptionInput}
                              value={subscriptionFilterValue}
                              class="px-2 py-1 rounded-md w-80 border-4 border-gray-300"
                            />
                            <section class="ml-auto px-2 py-2 rounded-md font-medium">
                              {/* {currentConfig.subscription.editionName == null ? "No Subscription selected" : currentConfig.subscription.name} */}
                              {currentConfig?.subscription?.arrayIndex == -1 ? "No Subscription selected" : (mpSubscriptionsData.data[currentConfig.subscription.arrayIndex].order.orderLines.filter(item => item.type == "ITEM"))[0].editionName}
                            </section>
                          </section>
                          {loadingSubscriptions
                            ? "Loading Subscriptions"
                            :
                            <table class=" text-sm text-left text-black">
                              <thead class="text-s border-b-2 border-[#DC0029] text-gray-700 uppercase bg-gray-300">
                                <tr key="header">
                                  <th scope="col" class="px-6 py-3">
                                    Edition Name
                                  </th>
                                  <th scope="col" class="px-6 py-3">
                                    Frequency
                                  </th>
                                  <th scope="col" class="px-6 py-3">
                                    Quantity
                                  </th>
                                  <th scope="col" class="px-6 py-3 hidden lg:block">
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="z-0">
                                {mpSubscriptionsData.data.filter((mpSubscription, index) => {
                                  mpSubscription.index = index
                                  if (mpSubscription.subscription.id.includes(subscriptionFilterValue)) {
                                    return mpSubscription
                                  }
                                }).map((mpSubscription) =>
                                  <tr class={`${currentConfig.subscription.arrayIndex === mpSubscription.index ? "bg-red-300" : "hover:bg-gray-300"} border-b bg-gray-200 border-[#DC0029] `} onClick={() => handleCurrentConfigChange([["subscription", "arrayIndex", mpSubscription.index]])} key={mpSubscription.subscription.id}>
                                    <td class="px-6 py-4">
                                      <section class="hover:underline hover:decoration-[#DC0029]">{(mpSubscription.order.orderLines.filter(item => item.type == "ITEM"))[0].editionName}</section>
                                    </td>
                                    <td class="px-6 py-4">
                                      {mpSubscription.order.paymentPlan.frequency}
                                    </td>
                                    <td class="px-6 py-4">
                                      {(mpSubscription.order.orderLines.filter(item => item.type == "ITEM"))[0].quantity}
                                    </td>
                                    <td class={`px-6 py-4 hidden lg:block
                                      ${mpSubscription.subscription.status == "ACTIVE"
                                        ? "text-green-700"
                                        : "text-[#DC0029]"
                                      } uppercase
                                    `}>
                                      {mpSubscription.subscription.status}
                                    </td>
                                  </tr>
                                )
                                }
                              </tbody>
                            </table>
                          }
                        </section>
                      }

                      {/* 3. Select the Billing User */}
                      {migrationMode - 1 == 0 && migrationModes[migrationMode - 1].steps[currentStep].id == 2 &&
                        <section class="flex flex-col">
                          {loadingUsers
                            ? "Loading Users"
                            :
                            <section>
                              <section class="flex py-2">
                                <section className="my-auto font-medium px-4">
                                  Current Billing User:
                                </section>
                                <section className="my-auto pl-14 font-medium px-4">
                                  {mpCompanyUserData.filter(member => member.user.uuid == currentConfig.subscription.userUUID)[0].user.email}

                                </section>
                              </section>
                              <section class="flex py-2">
                                <section className="my-auto font-medium px-4">
                                  Select the new Billing User:
                                </section>
                                {/* <select class="pl-2 pr-5" onChange={e => setCurrentConfig({ ...currentConfig, user: { email: mpCompanyUserData[parseInt(e.target.value)].user.email, uuid: mpCompanyUserData[e.target.value].user.uuid, externalId: mpCompanyUserData[e.target.value].user.externalId, activated: mpCompanyUserData[e.target.value].user.activated } })}>  */}
                                <select class="pl-2 pr-5" onChange={e => handleCurrentConfigChange([["user", "email", mpCompanyUserData[parseInt(e.target.value)].user.email], ["user", "uuid", mpCompanyUserData[e.target.value].user.uuid], ["user", "externalId", mpCompanyUserData[e.target.value].user.externalId], ["user", "activated", mpCompanyUserData[e.target.value].user.activated]])}>
                                  {mpCompanyUserData.map((user, index) =>
                                    <option value={index}>{user.user.email}</option>
                                  )}
                                </select>
                              </section>
                            </section>
                          }
                        </section>
                      }

                      {/* 4. New Quantity */}
                      {migrationMode - 1 == 0 && migrationModes[migrationMode - 1].steps[currentStep].id == 3 &&
                        <section>
                          <section class="flex py-2">
                            <section className="my-auto font-medium px-4">
                              Current quantity:
                            </section>
                            <section className="my-auto pl-14 font-medium px-4">
                              {currentConfig.subscription.quantity}
                            </section>
                          </section>
                          <section class="flex py-2">
                            <section className="my-auto font-medium px-4">
                              Insert the new quantity:
                            </section>
                            <input
                              type="number"
                              placeholder={currentConfig.subscription.quantity + 1}
                              // onChange={(handleQuantityInput)}
                              onChange={(e) => handleCurrentConfigChange([[null, "quantity", e.target.value]])}
                              value={currentConfig.quantity != 0 ? currentConfig.quantity : undefined}
                              class="px-2 py-1 rounded-md w-80 border-4 border-gray-300"
                            />
                          </section>
                        </section>
                      }

                      {/* 5. Select the Reseller Company */}
                      {migrationMode - 1 == 0 && migrationModes[migrationMode - 1].steps[currentStep].id == 4 &&
                        <section>
                          <section class="flex py-2">
                            <section className="my-auto font-medium px-4">
                              Current Reseller:
                            </section>
                            <section className="my-auto pl-24 font-medium px-4">
                              {(resellerCompanies.filter(company => company.id == currentConfig.company.resellerCompany))[0].name}
                            </section>
                          </section>
                          <section class="flex py-2">
                            <section className="my-auto font-medium px-4">
                              Select the Reseller Company:
                            </section>
                            {/* <select class="pl-2 pr-5" value={currentConfig.resellerCompany} onChange={e => setCurrentConfig({ ...currentConfig, resellerCompany: parseInt(e.target.value) })}> */}
                            <select class="pl-2 pr-5" value={currentConfig.resellerCompany} onChange={e => handleCurrentConfigChange([[null, "resellerCompany", parseInt(e.target.value)]])}>
                              {resellerCompanies.map((bu) =>
                                <option value={bu.id}>{bu.name}</option>
                              )}
                            </select>

                          </section>
                        </section>
                      }

                      {/* 6. Select the Creation Date */}
                      {migrationMode - 1 == 0 && migrationModes[migrationMode - 1].steps[currentStep].id == 5 &&
                        <section class="flex py-2">
                          <section className="my-auto font-medium px-4">
                            Select the Creation Date:
                          </section>
                          <input
                            type="date"

                            onChange={(e) => handleCurrentConfigChange([[null, "creationDate", e.target.value]])}
                            value={currentConfig.creationDate != 0 ? currentConfig.creationDate : `${new Date(currentConfig.subscription.creationDate).getFullYear()}-${(new Date(currentConfig.subscription.creationDate).getMonth() + 1)
                              .toString()
                              .padStart(2, '0')}-${new Date(currentConfig.subscription.creationDate).getDate().toString().padStart(2, '0')}`}
                            class="px-2 py-1 rounded-md w-80 border-4 border-gray-300"
                          />
                        </section>
                      }

                      {/* 7. Summary */}
                      {migrationMode - 1 == 0 && migrationModes[migrationMode - 1].steps[currentStep].id == 6 &&
                        <section>
                          <section class="flex py-2">

                            <section className="grid grid-cols-2 gap-4">
                              {/* Left Header */}
                              <section className="col-span-1 truncate">
                                <section class="py-4">EXTERNAL_ID</section>
                                <section class="py-4">EDITION_PRICING_UUID</section>
                                <section class="py-4">COMPANY_EXTERNAL_ID</section>
                                <section class="py-4">PURCHASED_BY_EXTERNAL_ID</section>
                                <section class="py-4">PRICING_UNIT</section>
                                <section class="py-4">UNITS</section>
                                <section class="py-4">DISCOUNT_CODE</section>
                                <section class="py-4">ORDER_STATUS</section>
                                <section class="py-4">SALES_REP_EXTERNAL_ID</section>
                                <section class="py-4">SALES_REP_COMPANY_EXTERNAL_ID</section>
                                <section class="py-4">START_DATE</section>
                                <section class="py-4">ISV:TRANSFER_ID</section>
                                <section class="py-4">ISV:VIP_MEMBERSHIP_ID</section>
                                <section class="py-4">ISV:VIP_SUBSCRIPTION_ID</section>
                                <section class="py-4">ISV:ADOBE_RESELLER_ID</section>
                                <section class="py-4">CUSTOM_ATTRIBUTE:Fachvertrieb</section>
                                <section class="py-4">CUSTOM_ATTRIBUTE:PONumber</section>
                              </section>
                              <section>
                                <section className="grid grid-cols-2 gap-4 truncate">
                                  {/* Left Header */}
                                  <section className="col-span-1">
                                    {/* EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, externalId: e.target.value })}
                                        onChange={(e) => handleCurrentConfigChange([[null, "externalId", e.target.value]])}
                                        value={currentConfig.externalId != null ? currentConfig.externalId : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                    </section>
                                    {/* EDITION_PRICING_UUID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { paymentPlanUUID: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["subscription", "paymentPlanUUID", e.target.value]])}
                                        value={currentConfig.subscription.paymentPlanUUID != null ? currentConfig.subscription.paymentPlanUUID : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* COMPANY_EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, company: { externalId: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["company", "externalId", e.target.value]])}
                                        value={currentConfig.company.externalId != null ? currentConfig.company.externalId : "will be set"}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* PURCHASED_BY_EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, user: { externalId: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["user", "externalId", e.target.value]])}
                                        value={currentConfig.user.externalId != null ? currentConfig.user.externalId : "will be set"}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* PRICING_UNIT */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { unit: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["user", "unit", e.target.value]])}
                                        value={currentConfig.quantity != 0 ? currentConfig.subscription.unit : "USER"}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* UNITS */}
                                    <section class="py-2">
                                      <input
                                        type="number"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, quantity: e.target.value })}
                                        onChange={(e) => handleCurrentConfigChange([["user", "quantity", e.target.value]])}
                                        value={currentConfig.quantity != 0 ? currentConfig.quantity : currentConfig.subscription.quantity + 1}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                    </section>

                                    {/* DISCOUNT_CODE */}
                                    <section class="py-2">
                                      <input
                                        type="number"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { discountId: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["subscription", "discountId", e.target.value]])}
                                        value={currentConfig.subscription.discountId != null ? currentConfig.subscription.discountId : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                      {/* {currentConfig.subscription.discountId != null && <p class="text-gray-600 text-center">Info: Bitte auf Gültigkeit prüfen</p>} */}
                                    </section>

                                    {/* ORDER_STATUS */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, orderStatus: e.target.value })}
                                        onChange={(e) => handleCurrentConfigChange([[null, "orderStatus", e.target.value]])}
                                        value={currentConfig.orderStatus != null ? currentConfig.orderStatus : "ACTIVE"}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* SALES_REP_EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        value={currentConfig.resellerCompany != -1 ? resellerCompanies[currentConfig.resellerCompany].salesUser : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* SALES_REP_COMPANY_EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        value={currentConfig.resellerCompany != -1 ? resellerCompanies[currentConfig.resellerCompany].salesCompany : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* START_DATE */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        value={currentConfig.creationDate != null ? currentConfig.creationDate + "T12:00:00+00:00" : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>
                                    {/* ISV:TRANSFER_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, transferId: e.target.value })}
                                        // onChange={(e) => handleCurrentConfigChange([[null, "transferId", e.target.value ]])}
                                        // value={currentConfig.transferId != null ? currentConfig.transferId : "NA"}
                                        value="Dummy"
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* ISV:VIP_MEMBERSHIP_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // type="number"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { adobeCustomerId: e.target.value } })}
                                        // onChange={(e) => handleCurrentConfigChange([["subscription", "adobeCustomerId", e.target.value ]])}
                                        // value={currentConfig.subscription.adobeCustomerId != 0 ? currentConfig.subscription.adobeCustomerId : undefined}
                                        value="Dummy"
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>
                                    {/* ISV:VIP_SUBSCRIPTION_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { externalAccountId: e.target.value } })}
                                        // onChange={(e) => handleCurrentConfigChange([["subscription", "externalAccountId", e.target.value ]])}
                                        value={currentConfig.subscription.externalAccountId != null ? currentConfig.subscription.externalAccountId : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>
                                    {/* ISV:ADOBE_RESELLER_ID */}
                                    <section class="py-2">
                                      <input
                                        type="number"
                                        value={currentConfig.subscription.adobeResellerId != 0 ? currentConfig.subscription.adobeResellerId : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* CUSTOM_ATTRIBUTE:Fachvertrieb */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { vb1: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["subscription", "vb1", e.target.value]])}
                                        value={currentConfig.subscription.vb1 != 0 ? currentConfig.subscription.vb1 : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                    </section>

                                    {/* CUSTOM_ATTRIBUTE:PONumber */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { poNumber: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["subscription", "poNumber", e.target.value]])}
                                        value={currentConfig.subscription.poNumber != null ? currentConfig.subscription.poNumber : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                    </section>

                                  </section>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      }

                      {/* Subscription Order */}
                      {/* 2. Select the Product */}
                      {migrationMode - 1 == 1 && migrationModes[migrationMode - 1].steps[currentStep].id == 1 &&
                        <section class="flex flex-col">
                          <section class="flex py-2">
                            <BiSearchAlt size={25} class="my-auto mx-4" />
                            <input
                              type="text"
                              placeholder="Search Product..."
                              onChange={(e) => setProductFilterValue(e.target.value)}
                              value={productFilterValue}
                              class="px-2 py-1 rounded-md w-80 border-4 border-gray-300"
                            />
                            <section class="ml-auto px-2 py-2 rounded-md font-medium">
                              {currentConfig.product.productName == null ? "No Product selected" : currentConfig.product.productName}
                            </section>
                          </section>
                          {loadingProducts
                            ? "Loading Products"
                            :
                            <table class=" text-sm text-left text-black">
                              <thead class="text-s border-b-2 border-[#DC0029] text-gray-700 uppercase bg-gray-300">
                                <tr key="header">
                                  <th scope="col" class="px-6 py-3">
                                    Product Name
                                  </th>
                                  <th scope="col" class="px-6 py-3">
                                    Product Id
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="z-0">
                                {mpProductData.data.filter((mpProduct) => {
                                  if (mpProduct.productName != null) {

                                    if (mpProduct.productId.toString().includes(productFilterValue) || mpProduct.productName.toLocaleLowerCase().includes(productFilterValue.toLocaleLowerCase())) {
                                      return mpProduct
                                    }
                                  }
                                }).filter((mpProduct, index, array) => {
                                  // Check if the index of the current object is the first occurrence of its name in the array
                                  return array.findIndex((item) => item.productId === mpProduct.productId) === index;
                                })
                                  .sort((a, b) => a.productName.localeCompare(b.productName))
                                  .map((mpProduct) =>
                                    // <tr class={`${currentConfig.company.uuid === mpCompany.uuid ? "bg-red-300" : "hover:bg-gray-300"} border-b bg-gray-200 border-[#DC0029] `} onClick={() => setCurrentConfig({ ...currentConfig, company: { name: mpCompany.name, uuid: mpCompany.uuid, status: mpCompany.status, externalId: mpCompany.externalId } })} key={mpCompany.uuid}>
                                    <tr class={`${currentConfig.product.productId === mpProduct.productId ? "bg-red-300" : "hover:bg-gray-300"} border-b bg-gray-200 border-[#DC0029] `} onClick={() => handleCurrentConfigChange([["product", "productName", mpProduct.productName], ["product", "productId", mpProduct.productId]])} key={mpProduct.productId}>
                                      <td class="px-6 py-4">
                                        <section class="hover:underline hover:decoration-[#DC0029]">{mpProduct.productName}</section>
                                      </td>
                                      <td class="px-6 py-4">
                                        {mpProduct.productId}
                                      </td>
                                    </tr>
                                  )
                                }

                              </tbody>
                            </table>
                          }
                        </section>
                      }

                      {/* 3. Select the Edition */}
                      {migrationMode - 1 == 1 && migrationModes[migrationMode - 1].steps[currentStep].id == 2 &&
                        <section class="flex flex-col">
                          {loadingProducts
                            ? "Loading Editions"
                            :
                            <section>
                              <section class="flex py-2 truncate">
                                {/* <section className="my-auto font-medium px-4">
                                  Select the Edition:
                                </section>
                                <select class="pl-2 pr-5" onChange={e => handleCurrentConfigChange([["product", "editionId", e.target.value], ["product", "editionName", e.target.options[e.target.selectedIndex].dataset.name], ["product", "pricingPlanUUID", mpProductData.data.filter(mpProduct => mpProduct.editionId == e.target.value)[0].pricingPlanItemUUID], ["subscription", "unit", mpProductData.data.filter(mpProduct => mpProduct.editionId == e.target.value)[0].pricingPlanUnit], ["subscription", "paymentPlanUUID", mpProductData.data.filter(mpProduct => mpProduct.editionId == e.target.value)[0].pricingPlanItemUUID]])}>
                                  {mpProductData.data.filter(mpProduct => mpProduct.productId == currentConfig.product.productId)
                                    .filter((mpProduct, index, array) => {
                                      // Check if the index of the current object is the first occurrence of its name in the array
                                     
                                      return array.findIndex((item) => item.editionName === mpProduct.editionName) === index;
                                    })
                                    .sort((a, b) => a.editionName.localeCompare(b.editionName))
                                    .map(edition =>
                                      <option value={edition.editionId} data-name={edition.editionName}>{edition.editionName}</option>
                                    )}
                                </select> */}
                                <section className="my-auto font-medium px-4">
                                  Edition:
                                </section>
                                {/* <select class="pl-2 pr-5" onChange={e => handleCurrentConfigChange([["product", "editionId", e.target.value], ["product", "editionName", e.target.options[e.target.selectedIndex].dataset.name], ["product", "pricingPlanUUID", mpProductData.data.filter(mpProduct => mpProduct.editionId == e.target.value)[0].pricingPlanItemUUID], ["subscription", "unit", mpProductData.data.filter(mpProduct => mpProduct.editionId == e.target.value)[0].pricingPlanUnit], ["subscription", "paymentPlanUUID", mpProductData.data.filter(mpProduct => mpProduct.editionId == e.target.value)[0].pricingPlanItemUUID]])}> */}


                                <p class="pl-2 pr-5">{mpProductData.data.filter(mpProduct => mpProduct.productId == currentConfig.product.productId)[0].editionName}</p>

                                {/* </select> */}
                              </section>
                                <section class="flex py-2">
                                  <section className="my-auto font-medium px-4">
                                    Select the Frequency:
                                  </section>
                                  <select class="pl-2 pr-5" onChange={e => handleCurrentConfigChange([
                                    ["product", "pricingPlanUUID", e.target.value],
                                    ["product", "editionId", mpProductData.data.filter(mpProduct => mpProduct.productId == currentConfig.product.productId)[0].editionId],
                                    ["subscription", "unit", mpProductData.data.filter(mpProduct => mpProduct.pricingPlanItemUUID == e.target.value)[0].pricingPlanUnit],
                                    ["subscription", "paymentPlanUUID", e.target.value]
                                  ])}>
                                    {mpProductData.data.filter(mpProduct => mpProduct.editionId == mpProductData.data.filter(mpProduct => mpProduct.productId == currentConfig.product.productId)[0].editionId)
                                      .map(pricingPlan =>
                                        <option value={pricingPlan.pricingPlanItemUUID} >{pricingPlan.pricingPlanFrequency}</option>
                                      )}
                                  </select>
                                </section>
                            </section>
                          }
                        </section>
                      }

                      {/* 4. Select the Billing User */}
                      {migrationMode - 1 == 1 && migrationModes[migrationMode - 1].steps[currentStep].id == 3 &&
                        <section class="flex flex-col">
                          {loadingUsers
                            ? "Loading Users"
                            :
                            <section>
                              <section class="flex py-2">
                                <section className="my-auto font-medium px-4">
                                  Select the new Billing User:
                                </section>
                                {/* <select class="pl-2 pr-5" onChange={e => setCurrentConfig({ ...currentConfig, user: { email: mpCompanyUserData[parseInt(e.target.value)].user.email, uuid: mpCompanyUserData[e.target.value].user.uuid, externalId: mpCompanyUserData[e.target.value].user.externalId, activated: mpCompanyUserData[e.target.value].user.activated } })}> */}
                                <select class="pl-2 pr-5" onChange={e => handleCurrentConfigChange([["user", "email", mpCompanyUserData[parseInt(e.target.value)].user.email], ["user", "uuid", mpCompanyUserData[e.target.value].user.uuid], ["user", "externalId", mpCompanyUserData[e.target.value].user.externalId], ["user", "activated", mpCompanyUserData[e.target.value].user.activated]])}>
                                  {mpCompanyUserData.map((user, index) =>
                                    <option value={index}>{user.user.email}</option>
                                  )}
                                </select>
                              </section>
                            </section>
                          }
                        </section>
                      }

                      {/* 5. Select the Adobe Customer */}
                      {migrationMode - 1 == 1 && migrationModes[migrationMode - 1].steps[currentStep].id == 4 &&
                        <section class="flex flex-col">
                          <section class="flex py-2">
                            <BiSearchAlt size={25} class="my-auto mx-4" />
                            <input
                              type="text"
                              placeholder="Search Adobe Customer..."
                              onChange={(e) => setAdobeCustomerFilterValue(e.target.value)}
                              value={adobeCustomerFilterValue}
                              class="px-2 py-1 rounded-md w-80 border-4 border-gray-300"
                            />
                            <section class="ml-auto px-2 py-2 rounded-md font-medium">
                              {currentConfig.subscription.adobeCustomerId == null ? "No Customer selected" : currentConfig.subscription.adobeCustomerId}
                            </section>
                          </section>
                          {loadingAdobeCustomers
                            ? "Loading Adobe Customers"
                            :
                            <table class=" text-sm text-left text-black">
                              <thead class="text-s border-b-2 border-[#DC0029] text-gray-700 uppercase bg-gray-300">
                                <tr key="header">
                                  <th scope="col" class="px-6 py-3">
                                    Customer Id
                                  </th>
                                  <th scope="col" class="px-6 py-3">
                                    Customer Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="z-0">
                                {adobeCustomerData.data.filter((adobeCustomer) => {
                                  if (adobeCustomer.customerId.includes(adobeCustomerFilterValue) || adobeCustomer.status.includes(adobeCustomerFilterValue) || adobeCustomer.companyProfile.companyName.toLocaleLowerCase().includes(adobeCustomerFilterValue.toLocaleLowerCase())) {
                                    return adobeCustomer
                                  }
                                })
                                  .sort((a, b) => b.customerId.localeCompare(a.customerId))
                                  .map((adobeCustomer) =>
                                    <tr class={`${currentConfig.subscription.adobeCustomerId === adobeCustomer.customerId ? "bg-red-300" : "hover:bg-gray-300"} border-b bg-gray-200 border-[#DC0029] `} onClick={() => handleCurrentConfigChange([["subscription", "adobeCustomerId", adobeCustomer.customerId]])} key={adobeCustomer.customerId}>
                                      <td class="px-6 py-4">
                                        <section class="hover:underline hover:decoration-[#DC0029]">{adobeCustomer.companyProfile.companyName}</section>
                                      </td>
                                      <td class="px-6 py-4">
                                        {adobeCustomer.customerId}
                                      </td>
                                    </tr>
                                  )
                                }
                              </tbody>
                            </table>
                          }
                        </section>
                      }

                      {/* 6. Select the Adobe Customer Order */}
                      {migrationMode - 1 == 1 && migrationModes[migrationMode - 1].steps[currentStep].id == 5 &&
                        <section class="flex flex-col">
                          <section class="flex py-2">
                            <section class="ml-auto px-2 py-2 rounded-md font-medium">
                              {currentConfig.subscription.externalAccountId == null ? "No Order selected" : currentConfig.subscription.externalAccountId}
                            </section>
                          </section>
                          {loadingAdobeCustomerOrders
                            ? "Loading Adobe Customer Orders"
                            :

                            <section>
                              <section class="z-0">
                                {adobeCustomerOrderData.data.orders.items.map((order, index) =>
                                  <section class={`${index == 0 ? "" : "border-t-8 border-gray-200"} font-medium bg-gray-100 p-4`}>
                                    <section class=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-y-8 gap-x-16 lg:gap-x-32 p-4">
                                      <section class="flex-col">
                                        <section>Order Id</section>
                                        <section class="font-normal">{order.orderId ? order.orderId : "-"}</section>
                                      </section>
                                      <section class="flex-col col-span-2">
                                        <section>External ReferenceId Id</section>
                                        <section class="font-normal">{order.externalReferenceId ? order.externalReferenceId : "-"}</section>
                                      </section>
                                      <section class="flex-col">
                                        <section>Order Type</section>
                                        <section class="font-normal">{order.orderType ? order.orderType : "-"}</section>
                                      </section>
                                      <section class="flex-col">
                                        <section>Status</section>
                                        <section class="font-normal">{order.status ? order.status : "-"}</section>
                                      </section>
                                      <section class="flex-col">
                                        <section>Currency Code</section>
                                        <section class="font-normal">{order.currencyCode ? order.currencyCode : "-"}</section>
                                      </section>
                                      <section class="flex-col col-span-2">
                                        <section>Creation Date (UTC)</section>
                                        <section class="font-normal">{order.creationDate ? order.creationDate.split("-")[2].substring(0, 2) + "." + order.creationDate.split("-")[1] + "." + order.creationDate.split("-")[0] + " " + order.creationDate.split("T")[1].substring(0, 5) + "Uhr" : "-"}</section>
                                      </section>
                                    </section>

                                    <section>
                                      {order.lineItems.map((lineItem) =>
                                        <section class={`${currentConfig.subscription.externalAccountId == lineItem.subscriptionId && "bg-gray-300 border-2 border-[#DC0029]"} hover:bg-gray-300 border-t-2 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 4xl:grid-cols-9 gap-y-8 gap-x-16 lg:gap-x-32 p-4`} onClick={() => handleCurrentConfigChange([["subscription", "externalAccountId", lineItem.subscriptionId]])}>
                                          <section class="flex-col col-span-2">
                                            <section>Ext Line Item Number</section>
                                            <section class="font-normal">{lineItem.extLineItemNumber ? lineItem.extLineItemNumber : "-"}</section>
                                          </section>
                                          <section class="flex-col col-span-2">
                                            <section>Offer Id</section>
                                            <section class="font-normal">{lineItem.offerId ? lineItem.offerId : "-"}</section>
                                          </section>
                                          <section class="flex-col col-span-4">
                                            <section>Edition Name</section>
                                            <section class="font-normal">{lineItem.offerId ? getEditionName(lineItem.offerId) == false ? lineItem.offerId : getEditionName(lineItem.offerId) : "-"}</section>
                                          </section>

                                          <section class="flex-col col-span-2">
                                            <section>Subscription Id</section>
                                            <section class="font-normal">{lineItem.subscriptionId ? lineItem.subscriptionId : "-"}</section>
                                          </section>
                                          <section class="flex-col">
                                            <section>Quantity</section>
                                            <section class="font-normal">{lineItem.quantity ? lineItem.quantity : "-"}</section>
                                          </section>
                                        </section>
                                      )}
                                    </section>
                                  </section>
                                )
                                }
                              </section>
                            </section>
                          }
                        </section>
                      }

                      {/* 7. Quantity */}
                      {migrationMode - 1 == 1 && migrationModes[migrationMode - 1].steps[currentStep].id == 6 &&
                        <section>
                          <section class="flex py-2">
                            <section className="my-auto font-medium px-4">
                              Insert the new quantity:
                            </section>
                            <input
                              type="number"
                              placeholder={1}
                              // onChange={(handleQuantityInput)}
                              onChange={(e) => handleCurrentConfigChange([[null, "quantity", e.target.value]])}
                              value={currentConfig.quantity != 0 ? currentConfig.quantity : undefined}
                              class="px-2 py-1 rounded-md w-80 border-4 border-gray-300"
                            />
                          </section>
                        </section>
                      }

                      {/* 5. Select the Reseller Company */}
                      {migrationMode - 1 == 1 && migrationModes[migrationMode - 1].steps[currentStep].id == 7 &&
                        <section>
                          <section class="flex py-2">
                            <section className="my-auto font-medium px-4">
                              Current Reseller:
                            </section>
                            <section className="my-auto pl-24 font-medium px-4">
                              {(resellerCompanies.filter(company => company.id == currentConfig?.company?.resellerCompany))[0]?.name}
                            </section>
                          </section>
                          <section class="flex py-2">
                            <section className="my-auto font-medium px-4">
                              Select the Reseller Company:
                            </section>
                            {/* <select class="pl-2 pr-5" value={currentConfig.resellerCompany} onChange={e => setCurrentConfig({ ...currentConfig, resellerCompany: parseInt(e.target.value) })}> */}
                            <select class="pl-2 pr-5" value={currentConfig.resellerCompany} onChange={e => handleCurrentConfigChange([[null, "resellerCompany", parseInt(e.target.value)]])}>
                              {resellerCompanies.map((bu) =>
                                <option value={bu.id}>{bu.name}</option>
                              )}
                            </select>

                          </section>
                        </section>
                      }

                      {/* 9. Select the Creation Date */}
                      {migrationMode - 1 == 1 && migrationModes[migrationMode - 1].steps[currentStep].id == 8 &&
                        <section class="flex py-2">
                          <section className="my-auto font-medium px-4">
                            Select the Creation Date:
                          </section>
                          <input
                            type="date"
                            onChange={(e) => handleCurrentConfigChange([[null, "creationDate", e.target.value]])}
                            value={currentConfig.creationDate != 0 ? currentConfig.creationDate : `${new Date().getFullYear()}-${(new Date().getMonth())
                              .toString()
                              .padStart(2, '0')}-${new Date(currentConfig.subscription.creationDate).getDate().toString().padStart(2, '0')}`}
                            class="px-2 py-1 rounded-md w-80 border-4 border-gray-300"
                          />
                        </section>
                      }
                      {/* 7. Summary */}
                      {migrationMode - 1 == 1 && migrationModes[migrationMode - 1].steps[currentStep].id == 9 &&
                        <section>
                          <section class="flex py-2">

                            <section className="grid grid-cols-2 gap-4">
                              {/* Left Header */}
                              <section className="col-span-1 truncate">
                                <section class="py-4">EXTERNAL_ID</section>
                                <section class="py-4">EDITION_PRICING_UUID</section>
                                <section class="py-4">COMPANY_EXTERNAL_ID</section>
                                <section class="py-4">PURCHASED_BY_EXTERNAL_ID</section>
                                <section class="py-4">PRICING_UNIT</section>
                                <section class="py-4">UNITS</section>
                                <section class="py-4">DISCOUNT_CODE</section>
                                <section class="py-4">ORDER_STATUS</section>
                                <section class="py-4">SALES_REP_EXTERNAL_ID</section>
                                <section class="py-4">SALES_REP_COMPANY_EXTERNAL_ID</section>
                                <section class="py-4">START_DATE</section>
                                <section class="py-4">ISV:TRANSFER_ID</section>
                                <section class="py-4">ISV:VIP_MEMBERSHIP_ID</section>
                                <section class="py-4">ISV:VIP_SUBSCRIPTION_ID</section>
                                <section class="py-4">ISV:ADOBE_RESELLER_ID</section>
                                <section class="py-4">CUSTOM_ATTRIBUTE:Fachvertrieb</section>
                                <section class="py-4">CUSTOM_ATTRIBUTE:PONumber</section>
                              </section>
                              <section>
                                <section className="grid grid-cols-2 gap-4 truncate">
                                  {/* Left Header */}
                                  <section className="col-span-1">
                                    {/* EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, externalId: e.target.value })}
                                        onChange={(e) => handleCurrentConfigChange([[null, "externalId", e.target.value]])}
                                        value={currentConfig.externalId != null ? currentConfig.externalId : null}
                                        class="px-2 py-1 rounded-md w-60  md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                    </section>
                                    {/* EDITION_PRICING_UUID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { paymentPlanUUID: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["subscription", "paymentPlanUUID", e.target.value]])}
                                        value={currentConfig.subscription.paymentPlanUUID != null ? currentConfig.subscription.paymentPlanUUID : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* COMPANY_EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, company: { externalId: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["company", "externalId", e.target.value]])}
                                        value={currentConfig.company.externalId != null ? currentConfig.company.externalId : "will be set"}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* PURCHASED_BY_EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, user: { externalId: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["user", "externalId", e.target.value]])}
                                        value={currentConfig.user.externalId != null ? currentConfig.user.externalId : "will be set"}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* PRICING_UNIT */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { unit: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["user", "unit", e.target.value]])}
                                        value={currentConfig.quantity != 0 ? currentConfig.subscription.unit : "USER"}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* UNITS */}
                                    <section class="py-2">
                                      <input
                                        type="number"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, quantity: e.target.value })}
                                        onChange={(e) => handleCurrentConfigChange([["user", "quantity", e.target.value]])}
                                        value={currentConfig.quantity != 0 ? currentConfig.quantity : currentConfig.subscription.quantity + 1}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                    </section>

                                    {/* DISCOUNT_CODE */}
                                    <section class="py-2">
                                      <input
                                        type="number"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { discountId: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["subscription", "discountId", e.target.value]])}
                                        value={currentConfig.subscription.discountId != null ? currentConfig.subscription.discountId : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                      {/* {currentConfig.subscription.discountId != null && <p class="text-gray-600 text-center">Info: Bitte auf Gültigkeit prüfen</p>} */}
                                    </section>

                                    {/* ORDER_STATUS */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, orderStatus: e.target.value })}
                                        onChange={(e) => handleCurrentConfigChange([[null, "orderStatus", e.target.value]])}
                                        value={currentConfig.orderStatus != null ? currentConfig.orderStatus : "ACTIVE"}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* SALES_REP_EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        value={currentConfig?.resellerCompany != -1 ? resellerCompanies[currentConfig?.resellerCompany]?.salesUser : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* SALES_REP_COMPANY_EXTERNAL_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        value={currentConfig?.resellerCompany != -1 ? resellerCompanies[currentConfig?.resellerCompany]?.salesCompany : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* START_DATE */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        value={currentConfig.creationDate != null ? currentConfig.creationDate + "T12:00:00+00:00" : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>
                                    {/* ISV:TRANSFER_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, transferId: e.target.value })}
                                        // onChange={(e) => handleCurrentConfigChange([[null, "transferId", e.target.value ]])}
                                        // value={currentConfig.transferId != null ? currentConfig.transferId : "NA"}
                                        value="Dummy"
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* ISV:VIP_MEMBERSHIP_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // type="number"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { adobeCustomerId: e.target.value } })}
                                        // onChange={(e) => handleCurrentConfigChange([["subscription", "adobeCustomerId", e.target.value ]])}
                                        // value={currentConfig.subscription.adobeCustomerId != 0 ? currentConfig.subscription.adobeCustomerId : undefined}
                                        value="Dummy"
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>
                                    {/* ISV:VIP_SUBSCRIPTION_ID */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { externalAccountId: e.target.value } })}
                                        // onChange={(e) => handleCurrentConfigChange([["subscription", "externalAccountId", e.target.value ]])}
                                        value={currentConfig.subscription.externalAccountId != null ? currentConfig.subscription.externalAccountId : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>
                                    {/* ISV:ADOBE_RESELLER_ID */}
                                    <section class="py-2">
                                      <input
                                        type="number"
                                        value={currentConfig.subscription.adobeResellerId != 0 ? currentConfig.subscription.adobeResellerId : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                        disabled={true}
                                      />
                                    </section>

                                    {/* CUSTOM_ATTRIBUTE:Fachvertrieb */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { vb1: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["subscription", "vb1", e.target.value]])}
                                        value={currentConfig.subscription.vb1 != 0 ? currentConfig.subscription.vb1 : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                    </section>

                                    {/* CUSTOM_ATTRIBUTE:PONumber */}
                                    <section class="py-2">
                                      <input
                                        type="text"
                                        // onChange={(e) => setCurrentConfig({ ...currentConfig, subscription: { poNumber: e.target.value } })}
                                        onChange={(e) => handleCurrentConfigChange([["subscription", "poNumber", e.target.value]])}
                                        value={currentConfig.subscription.poNumber != null ? currentConfig.subscription.poNumber : null}
                                        class="px-2 py-1 rounded-md w-60 md:w-80 lg:w-96 border-4 border-gray-300"
                                      />
                                    </section>

                                  </section>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      }

                    </section>

                    {/* Right Panel */}
                    <section class={` ${migrationMode == 1 && currentStep == 6 || migrationMode == 2 && currentStep == 9 ? "hidden" : "w-1/4"} bg-gray-200 m-2 border-gray-400 pb-10 rounded-lg p-4`}>
                      <section>
                        <section class="font-medium underline">
                          Aktuelle Konfiguration:
                        </section>
                        {currentConfig.company.name != null &&
                          <section>
                            <section class="font-medium mt-4">
                              Company Name
                            </section>
                            <section>
                              {currentConfig.company.name}
                            </section>
                          </section>
                        }
                        {currentConfig.company.uuid != null &&
                          <section>
                            <section class="font-medium mt-2">
                              Company UUID
                            </section>
                            <section>
                              {currentConfig.company.uuid}
                            </section>
                          </section>
                        }

                        {currentConfig.subscription.id != null &&
                          <section>
                            <section class="font-medium mt-2">
                              Subscription ID
                            </section>
                            <section>
                              {currentConfig.subscription.id}
                            </section>
                          </section>
                        }
                        {currentConfig.subscription.orderId != null &&
                          <section>
                            <section class="font-medium mt-2">
                              Order ID
                            </section>
                            <section>
                              {currentConfig.subscription.orderId}
                            </section>
                          </section>
                        }
                        {currentConfig.subscription.editionName != null &&
                          <section>
                            <section class="font-medium mt-2">
                              Edition Name
                            </section>
                            <section>
                              {currentConfig.subscription.editionName}
                            </section>
                          </section>
                        }
                        {currentConfig.subscription.paymentPlanUUID != null &&
                          <section>
                            <section class="font-medium mt-2">
                              PaymentPlan UUID
                            </section>
                            <section>
                              {currentConfig.subscription.paymentPlanUUID}
                            </section>
                          </section>
                        }
                        {currentConfig.user.uuid != null &&
                          <section>
                            <section class="font-medium mt-2">
                              User UUID
                            </section>
                            <section>
                              {currentConfig.user.uuid}
                            </section>
                          </section>
                        }
                        {currentConfig.quantity != 0 &&
                          <section>
                            <section class="font-medium mt-2">
                              Quantity
                            </section>
                            <section>
                              {currentConfig.quantity}
                            </section>
                          </section>
                        }
                        {currentConfig.resellerCompany != -1 && currentConfig.quantity != 0 &&
                          <section>
                            <section class="font-medium mt-2">
                              Reseller Company
                            </section>
                            <section>
                              {resellerCompanies[currentConfig?.resellerCompany]?.name}
                            </section>
                          </section>
                        }
                        {currentConfig.creationDate != 0 && currentConfig.resellerCompany != -1 &&
                          <section>
                            <section class="font-medium mt-2">
                              Creation Date
                            </section>
                            <section>
                              {currentConfig.creationDate}
                            </section>
                          </section>
                        }
                      </section>
                    </section>
                  </section>
                </section>
              }
            </section >
          ) : mpCompaniesData.message == "jwt expired"
            ? jwtExpired()
            : <section class="flex-grow">Failed Loading Migration Informations ({mpCompaniesData.message})</section>
  )
}

export default Migration;
