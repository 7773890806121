import { BsFillFileEarmarkFill } from "react-icons/bs";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "../oidc/AuthContext";

export default function Reports() {

  const { isLoggedIn, login, logout, user } = useAuth();

  return (
    !isLoggedIn.state
      ? isLoggedIn.loading
        ? <section></section>
        : <Navigate replace to="/" />
    : (
    <section class="w-10/12 flex-grow relative mx-auto flex flex-col text-left">

      <section className="text-lg font-medium my-4 text-center">Hier kannst du die aktuellen Adobe Reports herunterladen</section>

      <table class="text-sm text-left text-black">
        <thead class="text-s border-b-2 border-[#DC0029] text-gray-700 uppercase bg-gray-300 ">
          <tr>
            <th scope="col" class="px-6 py-3">
              ReportName
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-gray-100" >
            <th scope="row" class="px-6 py-4 font-medium text-black hover:underline hover:decoration-[#DC0029]">
              <Link to="https://ccmarketplaceadobereport.blob.core.windows.net/data/Adobe_MP_Kundenstatus.xlsx">
              <section class="flex flex-row">
                <BsFillFileEarmarkFill class="my-auto mr-4" />
                <a>Adobe Marketplace Kundenstatus</a>
              </section>
              </Link>
            </th>
          </tr>
        </tbody>
      </table>
    </section>
    )
  )
}