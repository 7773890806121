import React, { useState, useEffect } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { useAuth } from '../oidc/AuthContext';
import { BiSearchAlt } from 'react-icons/bi';


const Navbar = ({ usernameDropdown }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isLoggedIn, login, logout, user } = useAuth();
  const [customerId, setCustomerId] = useState("");

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <footer class=" bg-[#DC0029] w-auto z-40 text-white mt-10">
      <section class="grid grid-cols-1  sm:grid-cols-3 gap-y-8 gap-x-8 m-4 md:text-sm font-normal text-xs">

        <section class="text-left md:mx-auto">
          <section class="md:text-3xl text-xl sm:text-normal font-extrabold">
            CANCOM Marketplace
          </section>
          <section class="md:text-2xl text-lg sm:text-normal text-black font-bold">
            Adobe Kundenportal
          </section>
          <section class="mt-2">
          Made by CANCOM Marketplace
        </section>
        </section>

      <section class="text-left md:mx-auto md:mt-4">
        <section class="font-medium text-base pb-2">
          Contact
        </section>
        <section>
          We are happy to help you!
        </section>
        <section>
          MO-FR 9 am - 6 pm
        </section>
        <section>
          089-54054-5700
        </section>
        <section>
          We are happy to help you!
        </section>
        <section>
          marketplace@cancom.de
        </section>
      </section>

      <section class="text-left md:mx-auto md:mt-4">
        <section class="font-medium text-base pb-2">
          Helpfull Links
        </section>
        <section>
          <a href="https://marketplace.cancom.de">CANCOM Marketplace</a>
        </section>
        <section>
          <a href="https://cancom.de">CANCOM</a>
        </section>
      </section>

    </section>
    </footer >
  );
};

export default Navbar;
