import { Routes, Route } from 'react-router-dom';
import './App.css';
import Customers from './Components/customers/Customers';
import Customer from './Components/customer/Customer';
import Navbar from './Components/navbar/Navbar';
import Footer from './Components/footer/Footer';
import Reports from './Components/reports/Reports';
import Login from './Components/login/Login'
import Migration from './Components/migration/Migration';
import Home from './Components/home/Home';
import LoginCallback from './Components/loginCallback/LoginCallback';
import { LogoutPopUp } from './Components/GlobalComponents'
import { useState } from 'react';



function App() {

  const [isUsernameDropdownOpen, setIsUsernameDropdownOpen] = useState(false);
  
  const handleUsernameToggleDropdown = () => {
    setIsUsernameDropdownOpen(!isUsernameDropdownOpen)
  };

    return (
    <section className="App flex flex-col h-screen">
      <Navbar usernameDropdown={handleUsernameToggleDropdown} />
      <Routes>
        <Route path="/Customers" element={<Customers />} />
        <Route path="/Customer/:customerid" element={<Customer />} />
        <Route path="/Reports" element={<Reports />} />
        <Route path="/Migration" element={<Migration />} />
        <Route path="/"element={<Home />} />
        <Route path="/login" element={<section class="flex-grow"><Login /></section>} />
        <Route path="/callback" element={<LoginCallback />} />
      </Routes>
      <Footer />
      <LogoutPopUp isDropdownOpen={isUsernameDropdownOpen}  usernameDropdown={handleUsernameToggleDropdown}/>
    </section>
  );
}

export default App;
