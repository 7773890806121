

const Login = () => {

  function generateRandomState(length = 16) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let state = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      state += characters.charAt(randomIndex);
    }
  
    return state;
  }
  const url = `${process.env.REACT_APP_MARKETPLACE}/oauth2/authorize?response_type=code&scope=openid profile email&client_id=${process.env.REACT_APP_CLIENT_ID}&state=${generateRandomState()}&redirect_uri=${process.env.REACT_APP_HOSTNAME}/callback`
  window.location.href = url
  
}

export default Login;
