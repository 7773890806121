import { Link } from "react-router-dom";
import { useAuth } from "../oidc/AuthContext";

const Home = () => {

  const { isLoggedIn, login, logout, user } = useAuth();

  return (
    <section class="w-11/12 lg:w-10/12 relative flex-grow mx-auto flex flex-col text-left">

      <section className="text-lg font-medium my-4 text-center">
        {isLoggedIn.message ?
          <section class="">! {isLoggedIn.message} !</section>
          : <section></section>
        }
        {isLoggedIn.state
          ? (
            <section class="mt-2">
              <section class="text-2xl mt-6 mb-10">Willkommen {user.given_name}</section>
              <ul class="text-left list-disc ml-6">
                <li class="m-2">Da die Adobe-Schnittstelle keine Ausgabe aller Adobe Kunden unterstützt, müssen wir selbst eine Liste pflegen. Diese Liste findest du unter dem Punkt <Link className="underline font-semibold decoration-[#DC0029]" to="/customers">Customers</Link>, dort kannst du alle uns bekannten Adobe Kunden suchen und einsehen.</li>
                <li class="m-2">Falls du eine CustomerId hast, welche nicht in der Liste aufgeführt ist, kannst du diese in die Suchleiste eingeben und mit dem dort erscheinenden Button suchen.</li>
                <li class="m-2">Unter <Link className="underline font-semibold decoration-[#DC0029]" to="/reports">Reports</Link> kannst du alle Adobe Kundeninformationen als Excel-Datei herunterladen</li>
              </ul>
            </section>
          )
          : !isLoggedIn.loading && (
            <section class="mt-2">
              <Link className="underline font-bold text-xl decoration-[#DC0029] mb-10" to="/login">Login</Link>
              <ul class="text-left mt-6">
                <li class="m-2">Willkommen auf dem Adobe Kundenportal des CANCOM Cloud Marketplace´s.</li>
                <li class="m-2">Hier kannst du alle Marketplace Adobe Kunden suchen und einsehen.</li>
                <li class="m-2">Außerdem findest du hier alle Informationen über das Unternehmen, die Subscriptions und Orders.</li>
                <li class="m-2">Zusätzlich kannst du dir alle Adobe Kundeninformationen als Excel-Datei herunterladen.</li>
              </ul>
            </section>
          )
        }

      </section>
      <section className="text-xl font-medium my-4 ml-0">Changes:</section>
      <table class="text-sm text-left text-black">
        <thead class="text-s border-b-2 border-[#DC0029] text-gray-700 uppercase bg-gray-300 ">
          <tr>
            <th scope="col" class="pl-6 py-3">
              TimeStamp
            </th>
            <th scope="col" class="py-3">
              Change
            </th>
          </tr>
        </thead>
        <tbody class="text-s bg-gray-100">
          <tr class="border-b bg-gray-100 border-[#DC0029] hover:bg-gray-200">
            <td class="pl-6 py-4">
              10.09.2023
            </td>
            <td class="py-4">
              Go-Live
            </td>
          </tr>
          <tr class="border-b bg-gray-100 border-[#DC0029] hover:bg-gray-200">
          <td class="pl-6 py-4">
              11.10.2023
            </td>
            <td class="py-4">
              Fehlerbehebungen & Start/End Date unter Customer Benefits hinzugefügt
            </td>
          </tr>
        </tbody>
      </table>
    </section>

  )
}

export default Home;
