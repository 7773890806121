import React, { useState, useEffect } from "react";
import { authCode } from "../oidc/Authentication"
import { useAuth } from "../oidc/AuthContext";
import { Navigate } from "react-router-dom";


export default function LoginCallback() {

  const { isLoggedIn, login, logout, user } = useAuth();

  useEffect(() => {
    if (!isLoggedIn.state) {
      const loadPost = async () => {

        const queryParameters = new URLSearchParams(window.location.search)
        const code = queryParameters.get("code")

        const token = await authCode(code)

        login(token)

      }
      loadPost();
    }
  }, []);


  return (
    isLoggedIn.loading ? (
      <section class="flex-grow">Logging in...</section>
    ) :
      isLoggedIn.state ?
        <Navigate replace to="/" />
        : isLoggedIn.message ?
          <Navigate replace to="/" />
          : <section class="flex-grow">Logging in...</section>
  )
}
