import axios from 'axios';

async function getMPCompanies(user) {

  try {

    let axiosCompaniesRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "migration-mpcompanies?" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };

    const axiosResponse = await (await axios.request(axiosCompaniesRequest)).data

    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.mpCompanies }
    } else {

      return { state: false, message: axiosResponse.message }
    }
  } catch (err) {
    return { state: false, message: err.message }
  }
}

async function getMPProducts(user) {

  try {

    let axiosCompaniesRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "migration-mpproducts?" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };

    const axiosResponse = await (await axios.request(axiosCompaniesRequest)).data

    if (axiosResponse.success) {
      console.log(axiosResponse)
      return { state: true, data: axiosResponse.allMPAdobeProductInformations }
    } else {

      return { state: false, message: axiosResponse.message }
    }
  } catch (err) {
    return { state: false, message: err.message }
  }
}

async function getMPCompany(user, companyId) {

  try {

    let axiosCompaniesRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "migration-mpcompany?companyId=" + companyId + "&" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };

    const axiosResponse = await (await axios.request(axiosCompaniesRequest)).data

    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.mpCompany }
    } else {

      return { state: false, message: axiosResponse.message }
    }
  } catch (err) {
    return { state: false, message: err.message }
  }
}

async function getCompanyAssociations(user, companyId) {

  try {

    let axiosCompaniesRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "migration-mpcompanyassociations?companyId=" + companyId + "&" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };

    const axiosResponse = await (await axios.request(axiosCompaniesRequest)).data

    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.mpCompanyAssociations }
    } else {

      return { state: false, message: axiosResponse.message }
    }
  } catch (err) {
    return { state: false, message: err.message }
  }
}

async function getMPCompanyUser(user, companyId) {

  try {

    let axiosCompaniesRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "migration-mpcompanyuser?companyId=" + companyId + "&" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };

    const axiosResponse = await (await axios.request(axiosCompaniesRequest)).data

    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.mpCompanyUser }
    } else {

      return { state: false, message: axiosResponse.message }
    }
  } catch (err) {
    return { state: false, message: err.message }
  }
}

async function getMPSubscriptions(user, companyId) {

  try {

    let axiosCompaniesRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "migration-mpsubscriptions?companyId=" + companyId + "&" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };

    const axiosResponse = await (await axios.request(axiosCompaniesRequest)).data

    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.mpSubscriptions }
    } else {

      return { state: false, message: axiosResponse.message }
    }
  } catch (err) {
    return { state: false, message: err.message }
  }
}

async function postMPCompanyExternalId(user, companyId) {

  try {

    let axiosCompanyExternalIdRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "migration-mpcompanyexternalid?companyId=" + companyId + "&" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };

    const axiosResponse = await (await axios.request(axiosCompanyExternalIdRequest)).data

    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.mpCompany }
    } else {

      return { state: false, message: axiosResponse.message }
    }
  } catch (err) {
    return { state: false, message: err.message }
  }
}

async function postMPUserExternalId(user, userId) {

  try {

    let axiosUserExternalIdRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "migration-mpuserexternalid?userId=" + userId + "&" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };

    const axiosResponse = await (await axios.request(axiosUserExternalIdRequest)).data

    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.mpUser }
    } else {

      return { state: false, message: axiosResponse.message }
    }
  } catch (err) {
    return { state: false, message: err.message }
  }
}


async function getAdobeCustomers(user) {

  try {

    let axiosCustomerRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "customers?" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };
    const axiosResponse = await (await axios.request(axiosCustomerRequest)).data
    
    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.adobeData }
    } else {

      return { state: false, message: axiosResponse.message }
    }

  } catch (err) {
    return { state: false, message: err.message }
  }
}

async function getAdobeCustomer(user, customerId, refreshOrders, refreshInfo = false, refreshSubscriptions = false) {

  try {

    let axiosCustomerRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "customer?customerId=" + customerId + "&" + process.env.REACT_APP_FUNCTION_CODE + "&refreshInfo=" + refreshInfo + "&refreshSubscriptions=" + refreshSubscriptions + "&refreshOrders=" + refreshOrders,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };
    const axiosResponse = await (await axios.request(axiosCustomerRequest)).data
    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.adobeData }
    } else {
    
      return { state: false, message: axiosResponse.message }
    }
  } catch (err) {
    return { state: false, message: err.message }
  }
}

export { getMPCompanies, getMPSubscriptions, getMPCompany, getMPCompanyUser, postMPCompanyExternalId, postMPUserExternalId, getCompanyAssociations, getMPProducts, getAdobeCustomers, getAdobeCustomer }