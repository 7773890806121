import axios from 'axios';

async function authCode(code) {
    try {

        let url
        if (window.location.port) {

            url = process.env.REACT_APP_FUNCTION_URL + `auth?authorizationCode=${code}&redirectUri=${window.location.protocol}//${window.location.hostname}:${window.location.port}/callback&`+ process.env.REACT_APP_FUNCTION_CODE
        } else {
            url = process.env.REACT_APP_FUNCTION_URL + `auth?authorizationCode=${code}&redirectUri=${window.location.protocol}//${window.location.hostname}/callback&`+ process.env.REACT_APP_FUNCTION_CODE
        }
        let oidcTokenConfig = {
            method: 'GET',
            url,
            headers: {
                'Accept': 'application/json'
            }
        };

        let user = await axios.request(oidcTokenConfig)

        if (user.data.success) {
            return user.data.info
        } else {
            return user.data.errorMessage
        }

    } catch (err) {
        console.log(err)
    }
}

async function killAuth(sessionToken, csrfToken) {
    try {
        
        let logoutConfig = {
            method: 'GET',
            url: process.env.REACT_APP_FUNCTION_URL + "auth?logout=true&" + process.env.REACT_APP_FUNCTION_CODE,
            headers: {
                'Accept': 'application/json',
                'Authorization': "Bearer " + sessionToken,
                'X-CSRF-Token': csrfToken
            }
        };

        let logout = await axios.request(logoutConfig)

        return logout.data
    } catch (err) {
        console.log(err)
    }
}


export { authCode, killAuth}