import React, { useEffect, useState } from 'react'
import { useAuth } from '../oidc/AuthContext';
import getAdobeCustomers from './CustomersData';
import { Navigate, Link } from 'react-router-dom';
import { BiSearchAlt, BiRightTopArrowCircle } from "react-icons/bi";
import '../../App.css'

export default function Customers() {

  const { isLoggedIn, login, logout, user, relogin } = useAuth();
  const [adobeCustomerData, setAdobeCustomerData] = useState([])
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilteredValue] = useState("---");

  const handleInput = (e) => {
    e.preventDefault();
    e.target.value == ""
      ? setFilteredValue("---")
      : setFilteredValue(e.target.value);
  };

  useEffect(() => {
    const loadPost = async () => {

      setLoading(true)
      let adobeCustomers = await getAdobeCustomers(user)

      setAdobeCustomerData(adobeCustomers)
      setLoading(false)

    }
    if (isLoggedIn.state == true) {

      loadPost();
    }
  }, [isLoggedIn.state, user]);

  const jwtExpired = () => {
    relogin()
    return <section class="flex-grow">Loading Customers...</section>
  }

  return (
    !isLoggedIn.state
      ? isLoggedIn.loading
        ? <section></section>
        : <Navigate replace to="/" />
      : loading
        ? <section class="flex-grow">Loading Customers...</section>
        : adobeCustomerData.state
          ? (
            <section class="w-11/12 flex-grow lg:w-10/12 mx-auto flex flex-col">

              <section class={`flex transition-all ease-in-out duration-700 py-2 ${filterValue == "---" ? "text-2xl font-semibold" : "text-xl font-medium "}`}>
                {/* <section class="flex py-2"> */}

                {/* <div className={`flex my-auto transition-all duration-700 ${filterValue == "---" ? "text-2xl font-semibold" : "text-xl font-medium mr-2"}`}>Kunden Suchen:</div> */}
                <BiSearchAlt size={filterValue == "---" ? 45 : 35} class="my-auto mx-3 transition-all duration-700" />
                {/* <BiSearchAlt size={25} class="my-auto mx-4" /> */}
                <input
                  type="text"
                  placeholder="Suchen..."
                  onChange={handleInput}
                  // value={filterValue}
                  value={filterValue == "---" ? "" : filterValue}
                  // class={`flex ${filterValue == "---" ? "px-6 py-3 text-xl" : "px-2 py-1"} rounded-md border-4 border-gray-300 transition-all duration-700`}
                  class="px-2 py-1 rounded-md border-4 border-gray-300"
                  transi
                />
              </section>

              <table hidden={filterValue == "---" ? true : false} class=" text-sm text-left text-black">
                <thead class="text-s border-b-2 border-[#DC0029] text-gray-700 uppercase bg-gray-300">
                  <tr key="header">
                    <th scope="col" class="pl-6 py-3">
                      #
                    </th>
                    <th scope="col" class="py-3">

                    </th>
                    <th scope="col" class="px-6 py-3">
                      Customer Id
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Customer Name
                    </th>
                    <th scope="col" class="px-6 py-3 hidden lg:block">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody class="z-0">
                  {adobeCustomerData.data.filter((adobeCustomer) => {
                    if (adobeCustomer.customerId.includes(filterValue) || adobeCustomer.status.includes(filterValue) || adobeCustomer.companyProfile.companyName.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())) {
                      return adobeCustomer
                    }
                    
                  })
                  .sort((a, b) => b.customerId.localeCompare(a.customerId))
                  .map((customer, index) =>
                  <tr class="border-b bg-gray-200 border-[#DC0029] hover:bg-gray-300" key={index}>
                    <th scope="row" class="pl-6 py-4 font-medium text-black whitespace-nowrap">
                      {index + 1}
                    </th>
                    <td class="py-4">
                      <Link class="hover:text-[#DC0029]" to={`/customer/${customer.customerId}`}>< BiRightTopArrowCircle size={23} /></Link>
                    </td>
                    <td class="px-6 py-4">
                      <Link class="hover:underline hover:decoration-[#DC0029]" to={`/customer/${customer.customerId}`}>{customer.customerId}</Link>
                    </td>
                    <td class="px-6 py-4">
                      {customer.companyProfile.companyName}
                    </td>
                    <td class={`px-6 py-4 hidden lg:block
                            ${customer.status == "1000"
                        ? "text-green-700"
                        : "text-[#DC0029]"
                      } uppercase
                            `}>
                      {customer.status}
                    </td>
                  </tr>
                  )}

                </tbody>
              </table>

              {adobeCustomerData.data && adobeCustomerData.data.filter((adobeCustomer) => {
                if (adobeCustomer.customerId.includes(filterValue) || adobeCustomer.status.includes(filterValue) || adobeCustomer.companyProfile.companyName.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())) {
                  return adobeCustomer
                }
              }).length == 0 && /^\d{10}$/.test(filterValue) &&
                <Link class="hover:underline m-4 p-4  rounded-2xl bg-gray-300 font-medium  mx-auto border-2 border-[#DC0029] hover:decoration-[#DC0029]" to={`/customer/${filterValue}`}>Suche die CustomerId {filterValue} in der Adobe API</Link>
              }
            </section>
          ) : adobeCustomerData.message == "jwt expired"
            ? jwtExpired()
            : <section class="flex-grow">Failed Loading Customer ({adobeCustomerData.message})</section>
  )
}
