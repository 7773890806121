import axios from 'axios';

export default async function getAdobeCustomers(user) {

  try {

    let axiosCustomerRequest = {
      method: 'POST',
      url: process.env.REACT_APP_FUNCTION_URL + "customers?" + process.env.REACT_APP_FUNCTION_CODE,
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + user.id_token,
        'Content-Type': "application/json",
        'X-CSRF-Token': user.csrf_token
      },
      body: user.info
    };
    const axiosResponse = await (await axios.request(axiosCustomerRequest)).data
    
    if (axiosResponse.success) {

      return { state: true, data: axiosResponse.adobeData }
    } else {

      return { state: false, message: axiosResponse.message }
    }

  } catch (err) {
    return { state: false, message: err.message }
  }
}