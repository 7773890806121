import React, { useState, useEffect } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { useAuth } from '../oidc/AuthContext';
import { IoMdArrowDropdown } from 'react-icons/io';


const Navbar = ({ usernameDropdown }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isLoggedIn, login, logout, user } = useAuth();
  const [customerId, setCustomerId] = useState("");

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav class="border-[#DC0029] bg-gray-200 sticky top-0 z-50 border-b-2">
      <div class="flex flex-wrap items-center p-4 w-11/12 lg:w-10/12 mx-auto justify-between">
        <a href="https://marketplace.cancom.de" class="flex items-start">
          <img src="https://www.cancom.de/wp-content/uploads/sites/2/CANCOM_cloud_marketplace.svg" class="h-8 mr-3 hidden md:block" alt="Cancom Cloud Marketplace" />
          <section class="text-2xl font-bold pl-2 md:pl-0 text-[#DC0029]  hidden md:block">
            <div className="text-[#DC0029] block 2xl:border-0 xl:px-4 sm:px-4 px-0">|</div>
          </section>
          <section class="text-2xl font-bold">
            Adobe Kundenportal
          </section>
        </a>

        <section class="flex items-center">
          {isLoggedIn.state &&
            <ul class="space-x-2 sm:space-x-6 pr-8 2xl:hidden flex-row flex font-medium text-xl">
              <li>
                <div className="text-[#DC0029]">|</div>
              </li>
              <li>
                <button class="flex flex-row" onClick={() => usernameDropdown()}>
                  <section>
                    {user.given_name + " " + user.family_name[0]}.&nbsp;
                    </section>
                    <IoMdArrowDropdown size={30}/>
                </button>
              </li>
            </ul>
          }
          {isLoggedIn.state &&
          <button
            onClick={handleToggleDropdown}
            type="button"
            className="inline-flex items-center p-2 w-12 h-12 justify-center text-sm text-gray-500 rounded-lg 2xl:hidden  focus:outline-none hover:ring-2 hover:ring-gray-200 hover:bg-gray-300"
            aria-controls="navbar-dropdown"
            aria-expanded={isDropdownOpen ? 'true' : 'false'}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-8 h-8"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        }
        </section>
        <div
          class={`${isDropdownOpen ? "block" : "hidden"
            } w-full 2xl:block 2xl:w-auto`}
          id="navbar-dropdown">

          {isLoggedIn.state && (

            <ul class="font-medium text-xl pt-4 border-t-2 justify-center border-[#DC0029] flex 2xl:p-0 mt-4 space-x-8 2xl:mt-0 2xl:border-0 ">

              <li class="hidden 2xl:block">
                <div className="text-[#DC0029] block 2xl:border-0 2xl:p-0 px-0 sm:px-4 ">|</div>
              </li>
              <li>
                <NavLink to="/" className={({ isActive }) => (isActive ? 'text-[#DC0029]' : 'text-black hover:underline hover:decoration-[#DC0029]')} class="block 2xl:border-0 2xl:p-0">Home</NavLink>
              </li>
              <li>
                <NavLink to="Customers" className={({ isActive }) => (isActive ? 'text-[#DC0029]' : 'text-black hover:underline hover:decoration-[#DC0029]')} class="block 2xl:border-0 2xl:p-0">Customers</NavLink>
              </li>
              {/* <li>
                <input
                  type="text"
                  placeholder="CustomerId"
                  onChange={handleInput}
                  value={customerId}
                  class="w-32 rounded-md border-2 text-center border-gray-300 text-lg font-normal"
                />
              </li> */}
              <li>
                <NavLink to="Reports" className={({ isActive }) => (isActive ? 'text-[#DC0029]' : 'text-black hover:underline hover:decoration-[#DC0029]')} class="block 2xl:border-0 2xl:p-0 ">Reports</NavLink>
              </li>
              <li>
                <NavLink to="Migration" className={({ isActive }) => (isActive ? 'text-[#DC0029]' : 'text-black hover:underline hover:decoration-[#DC0029]')} class="block 2xl:border-0 2xl:p-0 ">Migration</NavLink>
              </li>
              <section class="space-x-8 2xl:flex hidden">
                <li>
                  <div className="text-[#DC0029] block 2xl:border-0 2xl:p-0 sm:px-4 px-0">|</div>
                </li>
                <li>
                  <button class="flex flex-row" onClick={() => usernameDropdown()}>
                  <section>
                    {user.given_name + " " + user.family_name[0]}.&nbsp;
                    </section>
                    <IoMdArrowDropdown size={30}/>
                </button>
                </li>
              </section>
            </ul>
          ) }
        </div>
      </div>
    </nav >
  );
};

export default Navbar;
