import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from './oidc/AuthContext';

const LogoutPopUp = ({isDropdownOpen, usernameDropdown}) => {
  const { isLoggedIn, login, logout, user } = useAuth();

  const handleLogout = () => {
    logout()
    usernameDropdown()
  };

  return (
    <section>

      {isDropdownOpen && (
        <NavLink to="/" onClick={handleLogout} className="fixed top-16 right-28 z-50 sm:right-32 md:right-32 lg:right-48 xl:right-52 2xl:right-40 3xl:right-60 w-32 bg-white rounded-lg shadow-lg hover:underline hover:decoration-[#DC0029] p-2">Logout</NavLink>
      )}
    </section>
  );
};

export { LogoutPopUp };
